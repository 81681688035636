import useTranslation from 'next-translate/useTranslation';
import { ErrorChipProps } from './props';
import styles from './styles.module.scss';

export function ErrorChip({ action, cardBg = false }: ErrorChipProps) {
  const { t } = useTranslation('common');

  return (
    <div
      className={`${styles.container} ${cardBg ? styles['container--cardBg'] : ''}`}
      onClick={action}
    >
      <div className={styles['container__title-container']}>
        <div className={styles.container__swoosh}>
          <i className="fad fa-exclamation-triangle" />
        </div>
      </div>
      <div className={styles.container__spacer} />
      <div className={styles.container__row}>
        <div className={styles.container__divider} />
        <div className={styles.container__message}>
          {t('error_secondary_description')}
        </div>
      </div>
    </div>
  );
}
