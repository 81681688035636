import { Button } from '@package/components';
import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';

export default function PaginationWithProgressSkeleton(): JSX.Element {
  return (
    <div className={styles.container}>
      <div className={styles.container__wrapper}>
        <div className={styles.container__wrapper__progress__container}>
          <div className={skeleton.progress} />
        </div>
        <Button isSkeleton />
      </div>
    </div>
  );
}
