import { LoaderContainer } from './styles';
import useTranslation from 'next-translate/useTranslation';

export function InfiniteScrollLoader() {
  const { t } = useTranslation('common');
  return (
    <LoaderContainer>
      <i className="fas fa-circle-notch fa-spin" />
      {t('loader_message')}
    </LoaderContainer>
  );
}
