import { SelectInputProps } from './props';
import { useEffect, useMemo, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import SelectInputOptions from './Options';
import styles from './styles.module.scss';
import skeleton from './styles-skeleton.module.scss';

export function SelectInput<T, V>({
  options = [],
  setValue,
  getOptionLabel,
  getOptionValue,
  value,
  disabled,
  cardBg,
  secondaryText = false,
  loading = false,
  loadingMore = false,
  invertOptionsPosition = false,
  loadMore = () => {},
  hasNextPage = false,
  isAsync = false,
  customComponents,
  error,
  refetch,
}: SelectInputProps<T, V>) {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<T | undefined>();
  const foundOption: T | undefined = useMemo(
    () =>
      options?.find(option => {
        return getOptionValue(option) === value;
      }),
    [getOptionValue, options, value],
  );
  const selectedLabel = selectedOption ? getOptionLabel(selectedOption) : '';

  function handleToggleFilterDropdown() {
    setOpen(state => !state);
  }

  function onChangeOption() {
    if (!foundOption) {
      return;
    }

    setSelectedOption(foundOption);
  }

  useEffect(onChangeOption, [foundOption]);

  if (loading) {
    return customComponents?.customSkeletonInput ? (
      customComponents?.customSkeletonInput()
    ) : (
      <div
        className={`${styles['selected-option']} ${cardBg ? styles['selected-option--card-bg'] : ''}`}
      >
        <div className={skeleton.text} />
        <div className={skeleton.chevron} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {customComponents?.customInput ? (
        customComponents?.customInput({
          handleOpen: handleToggleFilterDropdown,
          open,
          option: selectedOption,
        })
      ) : (
        <div
          className={`${styles['selected-option']} ${cardBg ? styles['selected-option--card-bg'] : ''} ${open ? styles['selected-option--open'] : ''} ${disabled ? styles['selected-option--disabled'] : ''} ${secondaryText ? styles['selected-option--secondary-text'] : ''}`}
          onClick={handleToggleFilterDropdown}
          id="input-select-button"
        >
          <p>{selectedLabel}</p>
          {disabled ? (
            <i className="fa-solid fa-lock" />
          ) : (
            <i className="fas fa-chevron-down" />
          )}
        </div>
      )}
      <AnimatePresence>
        {open && !disabled && (
          <SelectInputOptions
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            handleClose={handleToggleFilterDropdown}
            options={options}
            setValue={setValue}
            value={value}
            cardBg={cardBg}
            customComponents={customComponents}
            error={error}
            refetch={refetch}
            hasNextPage={hasNextPage}
            isAsync={isAsync}
            isInverted={invertOptionsPosition}
            loadMore={loadMore}
            loading={loading}
            loadingMore={loadingMore}
          />
        )}
      </AnimatePresence>
    </div>
  );
}
