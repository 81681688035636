import styles from './styles.module.scss';
import { GamesCarouselColumnProps } from './props';
import { useMemo } from 'react';
import { Image } from '@package/components';

const images = [
  'coinflip.webp',
  'crash.webp',
  'dice.webp',
  'double.webp',
  'fortune-double.webp',
  'frost.webp',
  'limbo.webp',
  'mines.webp',
  'plinko.webp',
  'slide.webp',
  'splash.webp',
  'tower.webp',
];

export default function HomeGamesCarouselColumn({
  column,
}: GamesCarouselColumnProps) {
  const Images = useMemo(
    () =>
      Array.from({ length: 12 }).map((_, index) => (
        <div
          key={`column-card-${column}-${index}`}
          className={`${styles.column__card} ${index % 2 == 0 ? styles['column__card--reverse'] : ''}`}
        >
          <Image
            width={200}
            height={200}
            bundle
            alt={`card-image-${column}-${index}`}
            src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/games/${((column * index) % 16) + 1}.webp`}
          />
        </div>
      )),
    [column],
  );

  return <div className={styles.column}>{Images}</div>;
}
