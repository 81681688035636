import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import { useLottiePlayer } from '@package/hooks';
import { NoDataComponentProps } from './props';

export function NoDataComponent({
  isSecondaryStyles,
  isSmall,
  tableStyles,
}: NoDataComponentProps) {
  const { t } = useTranslation('common');
  const { lottieAnimationRef } = useLottiePlayer({
    path: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/lotties/empty-search.json`,
    loop: true,
  });

  return (
    <div
      className={`${styles.container} ${isSecondaryStyles ? styles['container--secondary-styles'] : ''} ${isSmall ? styles['container--small'] : ''} ${tableStyles ? styles['container--table'] : ''}`}
    >
      <div className={styles.container__lottie} ref={lottieAnimationRef} />
      <div className={styles.container__spacer} />
      <div className={styles.container__wrapper}>
        <h6 className={styles.container__title}>{t('no_data_title')}</h6>
        <p className={styles.container__description}>{t('no_data_found')}</p>
      </div>
    </div>
  );
}
