import { useEffect, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import { ResendCodeButtonProps } from './props';

export function ResendCodeButton({
  onClick,
  isLoading,
  isSuccess,
  isSkeleton,
}: ResendCodeButtonProps) {
  const { t } = useTranslation('common');

  const [smsSendAt, setSmsSendAt] = useState(
    new Date(new Date().getTime() + 60000),
  );
  const [currentDate, setCurrentDate] = useState(new Date());
  const isButtonDisabled: boolean = smsSendAt > currentDate;
  const difference: number = useMemo(
    () =>
      (smsSendAt &&
        Math.trunc(
          Math.abs(
            ((smsSendAt as Date).getTime() - currentDate.getTime()) as number,
          ) / 1000,
        )) ||
      0,
    [smsSendAt, currentDate],
  );

  function onSuccess() {
    if (!isSuccess) {
      return;
    }

    setSmsSendAt(new Date(new Date().getTime() + 60000));
  }

  useEffect(onSuccess, [isSuccess]);

  function onMount() {
    let intervalId: NodeJS.Timeout;

    intervalId = setInterval(() => setCurrentDate(new Date()), 1000);

    return () => {
      clearInterval(intervalId);
    };
  }

  useEffect(onMount, []);

  if (isSkeleton) {
    return <div className={styles.skeleton} />;
  }

  return (
    <button
      className={styles.button}
      disabled={isButtonDisabled || isLoading}
      onClick={onClick}
      type="button"
    >
      {t('resend_code')}{' '}
      {isButtonDisabled ? `- ${difference > 60 ? 60 : difference}` : ''}
    </button>
  );
}
