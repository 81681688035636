import styled from 'styled-components';
import Color from 'color';
import { Image } from '@starsoft/common/components';
import Link from 'next/link';
import { CoreImageProps } from '../../Image/props';
import { getComputedColor } from '@package/src/utils/theme';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 228px;
  background-color: ${({ theme }) => theme.vars.palette.background.paper};
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    min-height: 188px;
  }
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 275px;
  padding: 16px;
  padding-left: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.25rem;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const ImageBackground = styled((props: CoreImageProps) => (
  <Image {...props} />
))`
  position: absolute;
  right: -16px;
  bottom: -16px;
  display: block;
  aspect-ratio: 17/9;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
  width: 100%;
  height: auto;
  user-select: none;
  z-index: 0;
  padding: 16px;
  opacity: 0.8;
`;

export const Button = styled(Link)`
  all: unset;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${({ theme }) => theme.vars.palette.primary.main};
  color: ${({ theme }) => theme.vars.palette.primary.contrastText};
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  position: relative;
  transition: background-color 0.25s ease-in-out;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;

  &:hover,
  &:focus {
    background-color: ${({ theme }) =>
      Color(getComputedColor(theme.vars.palette.primary.main))
        .darken(0.1)
        .toString()};
  }
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
`;

export const Title = styled.h3`
  font-size: 18px;
  line-height: 22px;
  text-wrap: nowrap;
  color: ${({ theme }) => theme.vars.palette.text.primary};
  font-weight: 600;
`;

export const Description = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.vars.palette.text.secondary};
  font-weight: 500;
`;

export const Chip = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background-color: ${({ theme }) => theme.vars.palette.primary.main};
  color: ${({ theme }) => theme.vars.palette.text.primary};
  width: fit-content;
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding: 6px 12px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
