import { useCallback, useEffect, useRef, useState } from 'react';
import { useFullScreenProps } from './props';

export function useFullScreen({ isDocument = false }: useFullScreenProps = {}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const handleFullScreenChange = useCallback(() => {
    setIsFullScreen(document.fullscreenElement === containerRef.current);
  }, []);

  const handleToggleFullScreen = useCallback(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen?.();
    }
    if (isDocument) {
      document.documentElement.requestFullscreen();
      return;
    }
    containerRef.current?.requestFullscreen?.();
  }, [isDocument]);

  function handleChangeFullScreenListener() {
    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }

  useEffect(handleChangeFullScreenListener, [handleFullScreenChange]);

  function toggleFullScreen() {
    const toggleFullScreen = (e: KeyboardEvent) => {
      if (isFullScreen && e.code === 'Escape') {
        document.exitFullscreen?.();
      }
    };

    window.addEventListener('keydown', toggleFullScreen);

    return () => {
      window.removeEventListener('keydown', toggleFullScreen);
    };
  }

  useEffect(toggleFullScreen, [isFullScreen]);

  return {
    handleToggleFullScreen,
    isFullScreen,
    containerRef,
  };
}
