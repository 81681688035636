import styled from 'styled-components';
import { CarouselContainerProps } from './props';
import Color from 'color';
import { ButtonProps } from '../Button/props';
import { getComputedColor } from '@package/src/utils/theme';

export const CarouselContainer = styled.div<CarouselContainerProps>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${({ length }) => length}, minmax(33%, 1fr));
  align-items: center;
  position: relative;
  gap: 8px;
  overflow-x: scroll;
  scroll-behavior: smooth !important;
  overflow-y: hidden;
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
  transition: all 0.3s;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  @media (max-width: 1115px) {
    grid-template-columns: repeat(${({ length }) => length}, minmax(65%, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(${({ length }) => length}, minmax(95%, 1fr));
  }
`;

const Button = styled.button`
  all: unset;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.vars.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  color: ${({ theme }) => theme.vars.palette.text.primary};
  opacity: 0;

  i {
    font-size: 14px;
  }

  &:hover {
    opacity: 1;
    background-color: ${({ theme }) =>
      Color(getComputedColor(theme.vars.palette.primary.main))
        .darken(0.2)
        .toString()};
  }

  @media (max-width: 768px) {
    opacity: 1;
  }
`;

export const ButtonLeft = styled((props: ButtonProps) => <Button {...props} />)`
  left: 0;
`;

export const ButtonRight = styled((props: ButtonProps) => (
  <Button {...props} />
))`
  right: 0;
`;

export const Container = styled.div`
  width: 100%;
  position: relative;

  &:hover {
    ${Button} {
      opacity: 1;
      background-color: ${({ theme }) =>
        Color(getComputedColor(theme.vars.palette.primary.main))
          .darken(0.2)
          .toString()};
    }
  }
`;
