import { Input } from '@starsoft/common/src/components/Input';
import { CurrencyInputProps } from './props';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import styles from './styles.module.scss';
import { Image } from '../Image';
import { WalletService } from '@package/services';
import { CoinID } from '@package/enums';

export function CurrencyInput<T extends FieldValues>({
  coin,
  setValue,
  defaultValue,
  value,
  isError,
  resetKey,
  isLoading,
  hideCurrency,
  ...props
}: CurrencyInputProps<T>) {
  const [touched, setTouched] = useState<boolean>(false);

  function handleSetTouched() {
    if (touched) {
      return;
    }

    setTouched(true);
  }

  const getMaskedValue = useCallback(
    (value: number): string => {
      return WalletService.maskCurrency({
        value,
        coinId: coin?.id ?? CoinID.USD,
        hideSymbol: true,
      });
    },
    [coin?.id],
  );

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    handleSetTouched();

    const maskedValue: string = WalletService.getMaskedInputValue({
      value: e.target.value,
      coinId: coin?.id,
      hideSymbol: true,
    });

    const unmaskedValue: number = WalletService.unmaskCurrency({
      value: maskedValue,
      coinId: coin.id,
    });

    setValue(unmaskedValue);
  }

  function onBlur() {
    handleSetTouched();
  }

  function getValue(): string {
    if (!coin || value === 0 || !value) {
      return '';
    }

    return getMaskedValue(value);
  }

  function onReset() {
    if (!resetKey) {
      return;
    }

    setTouched(false);
  }

  useEffect(onReset, [resetKey, setTouched]);

  function onCoinChange() {
    if (!coin) {
      return;
    }

    setValue(0);
    setTouched(false);
  }

  //eslint-disable-next-line
  useEffect(onCoinChange, [coin]);

  return (
    <Input
      onChange={onChange}
      type="string"
      defaultValue={
        typeof defaultValue !== 'undefined' && coin
          ? getMaskedValue(defaultValue)
          : undefined
      }
      hasPaddingRight
      isLoading={!coin || isLoading}
      isError={isError && touched}
      onBlur={onBlur}
      value={getValue()}
      customIcon={
        !!coin &&
        !hideCurrency && (
          <Image
            className={styles.coin}
            alt={`coin-icon-${coin?.name}`}
            width={20}
            height={20}
            src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/coins/rounded/${coin?.image}`}
          />
        )
      }
      {...props}
      placeholder={getMaskedValue(0)}
    />
  );
}
