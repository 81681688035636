import { useCallback, useEffect, useMemo, useRef } from 'react';
import { TabsProps } from './props';
import styles from './styles.module.scss';
import Tab from './Tab';
import { useCustomCarousel, useIsMobile } from '@package/hooks';

export function Tabs<T>({
  setTab,
  currentTab,
  tabs,
  i18nNamespace = 'common',
  isSmall,
  useContainer,
  renderTab,
  showIndicator,
  useMaxContent,
  invertColors,
  id = `tab-${tabs.toString()}`,
  isTiny,
  disabled,
}: TabsProps<T>) {
  const {
    containerRef,
    handleMouseDown,
    handleMouseLeave,
    handleMouseMove,
    handleMouseUp,
    isDragging,
  } = useCustomCarousel();
  const position = useMemo(
    () => tabs.findIndex(tab => tab.value == currentTab),
    [tabs, currentTab],
  );

  const indicatorRef = useRef(null);

  const handleAdjustIndicator = useCallback(() => {
    if (!indicatorRef.current || !containerRef.current || !showIndicator)
      return;

    const tabsContainer = containerRef.current;
    const indicatorEl = indicatorRef.current;
    const tabButtons = tabsContainer.querySelectorAll('button');

    if (!tabButtons[position]) return;

    const tabEl = tabButtons[position];
    console.log(tabEl.offsetWidth);

    const left = tabEl.offsetLeft;
    const width = tabEl.offsetWidth;

    indicatorEl.style.width = `${width}px`;
    indicatorEl.style.transform = `translateX(${left}px)`;
  }, [position, showIndicator, containerRef]);

  useEffect(() => {
    handleAdjustIndicator();

    window.addEventListener('resize', handleAdjustIndicator);
    return () => {
      window.removeEventListener('resize', handleAdjustIndicator);
    };
  }, [handleAdjustIndicator]);

  const Tabs = useMemo(
    () =>
      tabs.map(carouselTab =>
        !!renderTab ? (
          renderTab({
            currentTab,
            setTab,
            tab: carouselTab,
            i18nNamespace,
            isSmall,
          })
        ) : (
          <Tab
            key={`game-section-carousel-tab-${carouselTab.value}`}
            currentTab={currentTab}
            setTab={setTab}
            tab={carouselTab}
            useContainer={useContainer}
            i18nNamespace={i18nNamespace}
            isSmall={isSmall}
            useMaxContent={useMaxContent}
            isTiny={isTiny}
            showIndicator={showIndicator}
            disabled={disabled}
          />
        ),
      ),
    [
      disabled,
      setTab,
      currentTab,
      showIndicator,
      tabs,
      isTiny,
      i18nNamespace,
      isSmall,
      renderTab,
      useContainer,
      useMaxContent,
    ],
  );

  return (
    <div
      className={`${styles.container} ${isTiny ? styles['container--tiny'] : ''} ${useContainer ? styles['container--useContainer'] : ''} ${isDragging ? styles['container--dragging'] : ''} ${useMaxContent ? styles['container--max-content'] : ''} ${invertColors ? styles['container--invert-colors'] : ''} ${showIndicator ? styles['container--with-indicator'] : ''}`}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      ref={containerRef}
    >
      {Tabs}
      {showIndicator && (
        <div
          className={styles.container__indicator}
          id={id}
          ref={indicatorRef}
        />
      )}
    </div>
  );
}
