import { useRouter } from 'next/router';
import { NavigateProps } from './props';
import { useEffect } from 'react';

export function Navigate({ to, replace = false }: NavigateProps): null {
  const router = useRouter();

  function onMount() {
    if (replace) {
      router.replace(to);
      return;
    }

    router.push(to);
  }

  useEffect(onMount, [replace, router, to]);

  return null;
}
