import { PropsWithChildren } from 'react';
import styles from '../styles.module.scss';
import { FormGroupProps } from '../props';

export default function FormGroupSkeleton({
  children,
  loading,
  alignCenter,
}: PropsWithChildren<
  Pick<FormGroupProps, 'loading' | 'alignCenter'>
>): JSX.Element {
  return (
    <div
      className={`${styles.container} ${alignCenter ? styles['container--align-center'] : ''}`}
    >
      {loading?.label && (
        <div
          className={
            (loading?.secondary ?? true)
              ? styles['container__label__skeleton--secondary']
              : styles['container__label__skeleton']
          }
        />
      )}
      {loading?.description && (
        <div
          className={
            (loading?.secondary ?? true)
              ? styles['container__description__skeleton--secondary']
              : styles['container__description__skeleton']
          }
        />
      )}
      {children}
    </div>
  );
}
