import { useRouter } from 'next/router';
import { useMemo } from 'react';

export function usePathname(): string {
  const { asPath } = useRouter();
  const pathname: string = useMemo(() => {
    const url = new URL(asPath, process.env.NEXT_PUBLIC_DEPLOY_URL); // base URL is required but irrelevant
    return url.pathname;
  }, [asPath]);

  return pathname;
}
