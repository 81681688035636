import { TooltipProps } from 'recharts';
import { RechartsTooltip } from './styles';

export default function PieChartTooltip({
  payload,
}: TooltipProps<'percent', 'count'>) {
  return (
    <RechartsTooltip>
      {payload?.[0]?.name} <p>{payload?.[0]?.value}</p>
    </RechartsTooltip>
  );
}
