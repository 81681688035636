import { PropsWithChildren } from 'react';
import { DrawerProps } from './props';
import styles from './styles.module.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { containerVariants, overlayVariant } from './variants';
import { useClickOutside } from '@starsoft/common/hooks';

export function Drawer({
  onClose,
  open,
  children,
  isDisabled,
  absolute,
  className = '',
}: PropsWithChildren<DrawerProps>) {
  const ref = useClickOutside({
    open,
    onClickOutside: onClose,
  });

  return (
    <AnimatePresence>
      {open && !isDisabled && (
        <>
          <motion.div
            className={`${styles.overlay} ${absolute ? styles['overlay--absolute'] : ''}`}
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={overlayVariant}
          />

          <motion.div
            className={`${styles.container} ${absolute ? styles['container--absolute'] : ''} ${className}`}
            ref={ref}
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={containerVariants}
          >
            {children}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}
