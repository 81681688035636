import { SelectOptionWithHighlightProps } from './props';
import styles from './styles.module.scss';

export function SelectOptionWithHighlight<T>({
  option,
  selected,
  handleSelect,
  description,
  label,
}: SelectOptionWithHighlightProps<T>) {
  return (
    <div
      className={`${styles.container} ${selected ? styles['container--selected'] : ''}`}
      onClick={() => handleSelect(option)}
    >
      <p className={styles.container__label}>
        {label}
        <strong>{description}</strong>
      </p>
    </div>
  );
}
