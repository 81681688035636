import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';

export default function NavigationBreadcrumbsSkeleton() {
  return (
    <div className={styles.container}>
      <div className={skeleton.text} />

      <i className={`${styles.container__icon} fa-solid fa-chevron-right`} />

      <div className={skeleton.text} />
    </div>
  );
}
