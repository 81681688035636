import { useCallback, useEffect, useRef, useState } from 'react';
import { useToggle } from '../useToggle';

const useSteppedAnimation = (
  duration: number,
  active: boolean,
  isLoading: boolean,
) => {
  const ref = useRef<HTMLTableElement>(null);
  const [endAnimation, toggleEndAnimation] = useToggle(true);
  const [currentStep, setCurrentStep] = useState(0);

  const updateRowPosition = useCallback(
    (rows: NodeListOf<Element>, totalRows: number, stepHeight: number) => {
      if (isLoading) {
        return;
      }

      if (currentStep >= totalRows) {
        setCurrentStep(9);

        rows.forEach(row => {
          const element = row as HTMLElement;
          element.style.transition = 'none';
          element.style.transform = `translateY(-${stepHeight * (totalRows - 1 - 9)}px)`;
        });

        if (!endAnimation) {
          toggleEndAnimation();
        }

        return;
      }

      rows.forEach(row => {
        const element = row as HTMLElement;
        element.style.transition =
          'transform 1s cubic-bezier(0.47, 1.64, 0.41, 0.8)';
        element.style.transform = `translateY(-${stepHeight * (totalRows - 1 - currentStep)}px)`;
      });
      if (endAnimation) {
        toggleEndAnimation();
      }

      setCurrentStep(prev => prev + 1);
    },
    [currentStep, endAnimation, toggleEndAnimation, isLoading],
  );

  const stepAnimationHandler = useCallback(() => {
    if (!active || isLoading) return;

    const rows = ref.current?.querySelectorAll('.row') || [];
    const totalRows = rows.length;
    const stepHeight = 51;

    if (totalRows > 0) {
      const intervalId = setInterval(
        () =>
          updateRowPosition(rows as NodeListOf<Element>, totalRows, stepHeight),
        duration,
      );

      return () => clearInterval(intervalId);
    }
  }, [active, duration, updateRowPosition, isLoading]);

  useEffect(() => {
    if (active) {
      const cleanup = stepAnimationHandler();
      return () => cleanup && cleanup();
    }
  }, [stepAnimationHandler, active]);

  return { ref, endAnimation, currentStep };
};

export default useSteppedAnimation;
