import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyProps } from './props';
import { Input } from '@starsoft/common/src/components/Input';
import styles from './styles.module.scss';

export function Copy({
  textToCopy,
  label,
  isLoading = false,
  secondaryStyles,
  callback,
}: CopyProps) {
  function handleCopy() {
    if (typeof callback != 'undefined') {
      callback();
      return;
    }
  }

  if (isLoading) {
    return <Input isLoading />;
  }

  return (
    <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
      {/* @ts-ignore */}
      <div className={styles.container}>
        <Input
          value={label}
          readOnly
          rightIcon="fa-solid fa-copy"
          hasPaddingRight
          isContainerColor={secondaryStyles}
        />
      </div>
    </CopyToClipboard>
  );
}
