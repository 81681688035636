import { useMemo } from 'react';
import styles from './styles.module.scss';
import { SliderStepperProps } from './props';

export default function SliderStepper({
  currentStep,
  onStepClick,
  steps,
  absolute,
}: SliderStepperProps) {
  const Steps = useMemo(
    () =>
      Array.from({ length: steps }).map((_, index) => (
        <div
          className={`${styles.container__step} ${index == currentStep ? styles['container__step--active'] : ''}`}
          key={`slider-stepper-${index}`}
          onClick={() => onStepClick(index)}
        />
      )),
    [steps, currentStep, onStepClick],
  );

  return (
    <div
      className={`${styles.container} ${absolute ? styles['container--absolute'] : ''}`}
    >
      {Steps}
    </div>
  );
}
