import { CurrencyOptions } from '@package/models';
import {
  ConvertValueToCoinOptions,
  GetMaskedInputValue,
  MaskCurrencyMaskOptions,
  UnmaskCurrencyOptions,
} from './props';
import { coinFormattingMapping } from '@package/constants';
import { CoinID } from '@package/enums';

export class WalletService {
  static maskCurrency({
    value,
    coinId,
    hideSymbol: hideCurrency,
    smallerPrecision,
  }: MaskCurrencyMaskOptions): string {
    let options: CurrencyOptions | undefined =
      coinFormattingMapping.get(coinId);

    if (!options) {
      options = coinFormattingMapping.get(CoinID.USD) as CurrencyOptions;
    }

    const { symbol, decimal, separator, precision, isSymbolAfterValue } =
      options;

    const parts = Number(value ?? 0)
      .toFixed(smallerPrecision ? 2 : precision)
      .split('.');

    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    const decimalPart = parts[1];

    return `${!isSymbolAfterValue && !hideCurrency ? `${symbol} ` : ''}${integerPart}${decimal ? decimal + decimalPart : ''}${isSymbolAfterValue && !hideCurrency ? ` ${symbol}` : ''}`;
  }

  static unmaskCurrency({ value, coinId }: UnmaskCurrencyOptions): number {
    let options: CurrencyOptions | undefined =
      coinFormattingMapping.get(coinId);

    if (!options) {
      options = coinFormattingMapping.get(CoinID.USD) as CurrencyOptions;
    }

    const { decimal } = options;

    let numericalValue = value
      .replace(new RegExp(`[^0-9${decimal}]`, 'g'), '')
      .replace(new RegExp(`\\${decimal}`, 'g'), '.');

    return parseFloat(numericalValue);
  }

  static getMaskedInputValue({
    value,
    coinId,
    hideSymbol,
  }: GetMaskedInputValue): string {
    const options: CurrencyOptions = coinFormattingMapping.get(
      coinId,
    ) as CurrencyOptions;

    const precision: number = options.precision + 1;
    const divisionValue: number = Number('1'.padEnd(precision, '0'));
    const rawValue: string = value.replace(/[^0-9]/g, '');

    const numericValue: number = Number(rawValue) / divisionValue;
    const maskedValue: string = WalletService.maskCurrency({
      value: numericValue,
      coinId,
      hideSymbol,
    });

    return maskedValue;
  }

  static convertValueToCoin({
    value,
    valueCoinPrice,
    conversionCoinPrice,
  }: ConvertValueToCoinOptions) {
    const balanceInUSD: number = value * valueCoinPrice;
    return balanceInUSD / (conversionCoinPrice || 1);
  }
}
