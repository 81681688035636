import { TransProps } from 'next-translate';
import TransText from 'next-translate/TransText';

type ValueTransProps = Pick<TransProps, 'components'> & {
  text: string;
};

export function TransTextWithDefault({
  components,
  ...props
}: ValueTransProps) {
  return (
    <TransText
      components={{
        strong: <strong />,
        br: <br />,
        p: <p />,
        div: <div />,
        span: <span />,
        ...components,
      }}
      {...props}
    />
  );
}
