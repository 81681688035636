import styled from 'styled-components';
import { OptionProps } from './props';
import Color from 'color';
import { Image as NextImage } from '../../../Image';
import { CoreImageProps } from '@package/src/components/Image/props';
import { getComputedColor } from '@package/src/utils/theme';

export const CoinName = styled.p`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.vars.palette.text.secondary};
`;

export const CoinSymbol = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  transition: color 0.25s ease-in-out;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Option = styled.div<OptionProps>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px;
  background-color: ${({ theme, selected }) =>
    selected &&
    Color(getComputedColor(theme.vars.palette.primary.main))
      .alpha(0.2)
      .toString()};
  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: ${({ theme }) =>
      Color(getComputedColor(theme.vars.palette.primary.main))
        .alpha(0.2)
        .toString()};

    ${CoinSymbol} {
      color: ${({ theme }) => theme.vars.palette.text.primary};
    }
  }

  ${CoinSymbol} {
    color: ${({ theme, selected }) =>
      selected
        ? theme.vars.palette.text.primary
        : theme.vars.palette.text.secondary};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 0.25rem;
`;

export const Image = styled((props: CoreImageProps) => (
  <NextImage {...props} />
))`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 0.3rem;

  & .balance {
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 14px;
    color: ${({ theme }) => theme.vars.palette.text.primary};

    .decimal {
      color: ${({ theme }) => theme.vars.palette.text.secondary};
    }
  }

  & .value {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: ${({ theme }) => theme.vars.palette.text.secondary};
  }
`;
