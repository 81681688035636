import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import { useCountUp } from 'use-count-up';
import { useInView } from 'react-intersection-observer';
import { useMemo } from 'react';
import { Image } from '@package/components';

export default function HomeProviders() {
  const { t } = useTranslation('common');
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const Providers = useMemo(
    () =>
      Array.from({ length: 9 }).map((_, index) => (
        <Image
          width={300}
          height={150}
          src={
            `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/providers/${index}.svg` ??
            ''
          }
          alt={`provider-image-${index}`}
          key={`provider-image-${index}`}
          className={`${styles.container__provider} ${index < 9 ? 'desktop_only' : ''}`}
        />
      )),
    [],
  );

  const { value } = useCountUp({
    isCounting: inView,
    start: 0,
    end: 250,
    easing: 'easeInCubic',
  });

  return (
    <div className={styles.container} data-theme="dark" ref={ref}>
      <div className={styles.container__row}>
        <h6 className={styles.container__row__title}>
          {t('game_providers.title', {
            count: value,
          })}
        </h6>
        <p className={styles.container__row__description}>
          {t('game_providers.description')}
        </p>
      </div>
      <div className={styles.container__provider__wrapper}>{Providers}</div>
    </div>
  );
}
