import styles from './styles.module.scss';
import { PropsWithChildren } from 'react';
import { ModalLayoutProps } from './props';
import { useModal } from '@package/src/hooks/modals/useModal';
import { Modal } from '..';
import { ModalHeader } from '../Header';

export function ModalLayout({
  title,
  modal,
  condition = true,
  children,
  isLoading,
  smallMobilePadding,
  fitContent,
  clearParams = true,
  className,
  smallHeaderPadding,
}: PropsWithChildren<ModalLayoutProps>) {
  const { handleContainer, isOpen, onClose } = useModal(modal);

  function handleClose() {
    onClose(clearParams);
  }

  return (
    <Modal open={isOpen && condition} onClose={handleClose}>
      <div
        className={`${styles.container} ${fitContent ? styles['container--fit-content'] : ''} ${smallMobilePadding ? styles['container--small-mobile-padding'] : ''} ${className ? className : ''}`}
        onClick={handleContainer}
      >
        <ModalHeader
          title={title}
          onClose={handleClose}
          isLoading={isLoading}
          smallMobilePadding={smallMobilePadding}
          smallPadding={smallHeaderPadding}
        />
        <div className={styles.content}>{children}</div>
      </div>
    </Modal>
  );
}
