import { ToggleRenderProps } from './props';
import styles from './styles.module.scss';

export function ToggleRender({
  desktopComponent,
  mobileComponent,
}: ToggleRenderProps) {
  return (
    <div className={styles.container}>
      <div className={styles.container__desktop__only}>{desktopComponent}</div>
      <div className={styles.container__mobile__only}>{mobileComponent}</div>
    </div>
  );
}
