import { Chain } from '@package/models';
import { FCOptionProps } from '../../props';
import styles from './styles.module.scss';

export function ChainOption({
  handleSelect,
  option,
  selected,
}: Omit<FCOptionProps<Chain>, 'key'>) {
  function handleClick() {
    handleSelect(option);
  }
  return (
    <div
      className={`${styles.container} ${selected ? styles['container--selected'] : ''}`}
      onClick={handleClick}
    >
      <div className={styles.container__title}>{option.name}</div>
    </div>
  );
}
