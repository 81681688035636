import { SwitchProps } from './props';
import styles from './styles.module.scss';

export function Switch({
  label,
  disabled,
  isChecked,
  onChange,
  isLoading,
  flexEnd,
}: SwitchProps) {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (onChange) {
      onChange();
    }
  }

  if (isLoading) {
    return <div className={styles.skeleton} />;
  }

  return (
    <label
      className={`${styles.container} ${flexEnd ? styles['container--end'] : ''}`}
    >
      <div
        className={`${styles.switch} ${isChecked ? styles['switch--checked'] : ''} ${
          isLoading || disabled ? styles['switch--disabled'] : ''
        }`}
      >
        <div className={styles.switch__button}>
          {isLoading && (
            <i className={`${styles.spinner} fas fa-circle-notch fa-spin`} />
          )}
        </div>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleChange}
          className={styles.switch__input}
        />
      </div>

      {label &&
        (isLoading ? (
          <div className={styles['label-skeleton']} />
        ) : (
          <span className={styles.container__label}>{label}</span>
        ))}
    </label>
  );
}
