import { TableHeadSortLabelProps } from './props';
import styles from './styles.module.scss';

export default function TableHeadSortLabel({
  active,
  direction,
  onClick,
  children,
  preventOrder,
}: TableHeadSortLabelProps) {
  return (
    <div
      className={`${styles.cell} ${preventOrder ? styles['cell--disabled'] : ''}`}
      onClick={onClick}
    >
      {children}
      {active && (
        <div
          className={`${styles.cell__icon} ${styles[`cell__icon--${direction}`]}`}
        >
          <i className="fa-solid fa-arrow-up" />
        </div>
      )}
    </div>
  );
}
