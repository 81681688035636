import { useLottiePlayer } from '@starsoft/common/hooks';
import { InfoProps } from './props';
import styles from './styles.module.scss';

export function Info({ description, lottie, title }: InfoProps) {
  const { lottieAnimationRef } = useLottiePlayer({
    path: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/lotties/${lottie}`,
  });

  return (
    <div className={styles.container}>
      <div className={styles.container__lottie} ref={lottieAnimationRef} />
      <h6 className={styles.container__title}>{title}</h6>
      <p className={styles.container__description}>{description}</p>
    </div>
  );
}
