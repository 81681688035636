import { useCallback, useEffect, useState } from 'react';

export function useDetectVerticalScroll() {
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);

  const handleScrollEvent = useCallback(() => {
    const maxY =
      typeof window !== 'undefined' && window.innerWidth <= 768 ? 50 : 150;
    const backgroundVisible =
      typeof window !== 'undefined' && window.scrollY > maxY;
    setHasScrolled(backgroundVisible);
  }, []);

  const handleScroll = () => {
    window.addEventListener('scroll', handleScrollEvent);

    handleScrollEvent();

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  };

  useEffect(handleScroll, [handleScrollEvent]);

  return { hasScrolled };
}
