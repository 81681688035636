import { CheckboxProps } from './props';
import styles from './styles.module.scss';

export function Checkbox({
  isChecked,
  onClick,
  isLoading,
  isLarge,
  disabled,
}: CheckboxProps) {
  if (isLoading) {
    return (
      <div
        className={`${styles.skeleton} ${isLarge ? styles['skeleton--large'] : ''}`}
      />
    );
  }

  return (
    <div
      className={`${styles.checkbox} ${isChecked ? styles['checkbox--checked'] : ''} ${isLarge ? styles['checkbox--large'] : ''} ${disabled ? styles['checkbox--disabled'] : ''}`}
      onClick={onClick}
    >
      <i className="fa-solid fa-check" />
    </div>
  );
}
