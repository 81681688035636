import { PropsWithChildren } from 'react';
import styles from './styles.module.scss';
import { FormGroupProps } from './props';
import FormGroupSkeleton from './Skeleton';
import useTranslation from 'next-translate/useTranslation';

export function FormGroup({
  label,
  info,
  children,
  className = '',
  description,
  loading,
  isLoading,
  optional,
  alignCenter,
  primary,
}: PropsWithChildren<FormGroupProps>): JSX.Element {
  const { t } = useTranslation('common');

  if (isLoading) {
    return (
      <FormGroupSkeleton loading={loading} alignCenter={alignCenter}>
        {children}
      </FormGroupSkeleton>
    );
  }

  return (
    <div
      className={`${styles.container} ${alignCenter ? styles['container--align-center'] : ''}`}
    >
      {label && (
        <label
          className={`${styles['container__label']} ${primary ? styles['container__label--primary'] : ''}`}
        >
          {label}{' '}
          {optional && (
            <span
              className={`${styles.container__label} ${styles['container__label--secondary']}`}
            >
              ({t('optional_label')})
            </span>
          )}
          {info}
        </label>
      )}
      {description && (
        <label className={styles['container__description']}>
          {description}
        </label>
      )}
      <div className={className}>{children}</div>
    </div>
  );
}
