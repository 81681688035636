import { useLottiePlayer } from '@package/hooks';
import { TransWithDefault } from '../TransWithDefault';
import { EmptySearchProps } from './props';
import styles from './styles.module.scss';

export default function EmptySearch({ search }: EmptySearchProps) {
  const { lottieAnimationRef } = useLottiePlayer({
    path: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/lotties/empty-search.json`,
  });

  return (
    <div className={styles.container}>
      <div className={styles.container__lottie} ref={lottieAnimationRef} />
      <p className={styles.container__text}>
        <TransWithDefault
          i18nKey={search ? 'common:not_found_description' : 'common:no_result'}
          values={{
            search: search,
          }}
        />
      </p>
    </div>
  );
}
