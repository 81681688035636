import { Skeleton } from '@package/components';
import styled from 'styled-components';

export const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.vars.palette.text.primary};
`;

export const LabelSkeleton = styled((props: any) => <Skeleton {...props} />)`
  width: 30%;
  height: 16px;
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
`;

export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
