import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';

export default function CounterSkeleton() {
  return (
    <div className={styles.row}>
      <div className={styles.row__wrapper}>
        <div className={styles.row__wrapper__card}>
          <div className={skeleton.value} />
        </div>
        <div className={styles.row__wrapper__label}>
          <div className={skeleton.label} />
        </div>
      </div>
      <div className={styles.row__wrapper}>
        <div className={styles.row__wrapper__card}>
          <div className={skeleton.value} />
        </div>
        <div className={styles.row__wrapper__label}>
          <div className={skeleton.label} />
        </div>
      </div>
      <div className={styles.row__wrapper}>
        <div className={styles.row__wrapper__card}>
          <div className={skeleton.value} />
        </div>
        <div className={styles.row__wrapper__label}>
          <div className={skeleton.label} />
        </div>
      </div>
      <div className={styles.row__wrapper}>
        <div className={styles.row__wrapper__card}>
          <div className={skeleton.value} />
        </div>
        <div className={styles.row__wrapper__label}>
          <div className={skeleton.label} />
        </div>
      </div>
    </div>
  );
}
