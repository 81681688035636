import styles from './styles.module.scss';
import { AutocompleteSelectorOptionWithImageProps } from './props';
import { Image } from '@package/components';

export function AutocompleteSelectorOptionCoinWithImage({
  option,
  selected,
  handleSelect,
  isContainerColor,
}: AutocompleteSelectorOptionWithImageProps) {
  function handleClick() {
    handleSelect(option);
  }
  return (
    <div
      className={`${styles.option} ${selected ? styles['option--selected'] : ''} ${isContainerColor ? styles['option--container-color'] : ''}`}
      onClick={handleClick}
    >
      <Image
        alt={`icon-${option?.name}`}
        format="square"
        width={32}
        height={32}
        className={styles.option__image}
        src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/coins/rounded/${option.image}`}
        secondarySkeleton
      />
      <div className={styles.option__column}>
        <p className={styles.option__symbol}>{option.symbol}</p>
        <p className={styles.option__name}>{option.name}</p>
      </div>
    </div>
  );
}
