import styles from './styles.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { CounterProps } from './props';
import CounterSekeleton from './Skeleton';

export function Counter({
  isLoading,
  countdown,
  isSmall,
  hideDays,
}: CounterProps) {
  const { t } = useTranslation('common');
  const { days, hours, minutes, seconds } = countdown;

  if (isLoading) {
    return <CounterSekeleton />;
  }

  return (
    <div className={`${styles.row} ${isSmall ? styles['row--small'] : ''}`}>
      {!hideDays && (
        <div className={styles.row__wrapper}>
          <div className={styles.row__wrapper__card}>{days}</div>
          <div className={styles.row__wrapper__label}>{t('days')}</div>
        </div>
      )}
      <div className={styles.row__wrapper}>
        <div className={styles.row__wrapper__card}>{hours}</div>
        <div className={styles.row__wrapper__label}>{t('hours')}</div>
      </div>
      <div className={styles.row__wrapper}>
        <div className={styles.row__wrapper__card}>{minutes}</div>
        <div className={styles.row__wrapper__label}>{t('minutes')}</div>
      </div>
      <div className={styles.row__wrapper}>
        <div className={styles.row__wrapper__card}>{seconds}</div>
        <div className={styles.row__wrapper__label}>{t('seconds')}</div>
      </div>
    </div>
  );
}
