import { TooltipProps } from 'recharts';
import { RechartsTooltip } from './styles';
import useTranslation from 'next-translate/useTranslation';

export function BarChartTooltip({ payload }: TooltipProps<'percent', 'count'>) {
  const { t } = useTranslation('investment');

  const isProfit = Number(payload?.[0]?.value) >= 0;

  return (
    <RechartsTooltip>
      {t(isProfit ? 'profit_tooltip' : 'loss_tooltip')}{' '}
      <p>{Number(payload?.[0]?.value).toFixed(2)} USDT</p>
    </RechartsTooltip>
  );
}
