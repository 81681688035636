import React, { PropsWithChildren } from 'react';
import styles from './styles.module.scss';
import Header from './Header';
import Footer from './Footer';
import { Animation } from '@package/components';

export default function Layout({ children }: PropsWithChildren) {
  return (
    <React.Fragment>
      <Header />
      <div className={styles.container}>
        <div className={styles.container__wrapper}>{children}</div>
      </div>
      <Animation type="fadeInUp" triggerOnce>
        <Footer />
      </Animation>
    </React.Fragment>
  );
}
