import { DateRangePicker as MUIDateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRangeProps } from './props';
import { DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import styles from './styles.module.scss';
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import getDateFNSLocale from '@package/src/utils/i18n/getDateFNSLocale';
import getDatePickerTranslation from '@package/src/utils/i18n/getDatePickerTranslation';

export function DateRangePicker({
  range,
  setRange,
  isDefault,
  isLoading,
  label,
  isSmall,
}: DateRangeProps): JSX.Element {
  const { t, lang } = useTranslation('common');
  const locale = getDateFNSLocale(lang);
  const translation = getDatePickerTranslation(lang);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [tempRange, setTempRange] = useState<DateRange<Date>>(range);

  const deleteWatermark = () => {
    function remover() {
      const element = Array.from(document.querySelectorAll('div')).find(
        el => el.textContent == 'MUI X: Missing license key',
      );

      element?.remove();
      console.clear();
    }

    setTimeout(remover, 0);
    setTimeout(remover, 100);
    setTimeout(remover, 200);
  };

  function handleTempDate(date: DateRange<Date>) {
    setTempRange(date);
  }

  function handleDateClose() {
    if (!tempRange) {
      return;
    }

    setRange(tempRange);
  }

  if (isLoading) {
    return (
      <div
        className={`${styles.container} ${isDefault ? styles['container--default'] : ''}`}
      >
        <div className={styles['title-skeleton']} />
        <div className={styles.row}>
          <div className={styles['input-skeleton']} />
          <div className={styles['input-skeleton']} />
        </div>
      </div>
    );
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locale}
      localeText={
        translation.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <MUIDateRangePicker
        value={tempRange}
        label={t('selector_placeholder')}
        onChange={handleTempDate}
        onClose={handleDateClose}
        onOpen={deleteWatermark}
        renderInput={(startProps, endProps) => (
          <div
            className={`${styles.container} ${isDefault ? styles['container--default'] : ''} ${isSmall ? styles['container--small'] : ''}`}
            onClick={startProps.onClick}
          >
            <h6 className={styles['container__title']}>
              {label ? label : t('date_label')}:
            </h6>

            <div className={styles.row}>
              <input
                className={styles['date-input']}
                ref={startProps.inputRef as React.Ref<HTMLInputElement>}
                {...startProps.inputProps}
                placeholder={t('start_placeholder') as string}
                readOnly={isMobile}
              />

              <i className={`${styles.arrow} fa-solid fa-arrow-right`} />

              <input
                className={styles['date-input']}
                ref={endProps.inputRef as React.Ref<HTMLInputElement>}
                {...endProps.inputProps}
                placeholder={t('end_placeholder') as string}
                readOnly={isMobile}
              />
            </div>
          </div>
        )}
      />
    </LocalizationProvider>
  );
}
