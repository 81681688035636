import { useInfiniteScroll } from '@starsoft/common/hooks';
import { OptionsProps } from './props';
import { useClickOutside } from '@starsoft/common/hooks';
import { m as motion } from 'framer-motion';
import { dropIn } from '../variants';
import styles from './styles.module.scss';
import skeleton from './styles-skeleton.module.scss';
import { memo } from 'react';
import { ErrorCard } from '@package/components';

function SelectInputOptions<T, V>({
  getOptionLabel,
  getOptionValue,
  handleClose,
  cardBg,
  customComponents,
  isInverted = false,
  options,
  loadingMore = false,
  loadMore = () => {},
  hasNextPage = false,
  isAsync = false,
  loading = false,
  setValue,
  value,
  error,
  refetch,
}: OptionsProps<T, V>) {
  const { ref } = useInfiniteScroll({
    hasMore: hasNextPage,
    isLoading: loadingMore || loading,
    loadMore,
    condition: isAsync,
  });

  useClickOutside({
    open: true,
    onClickOutside: handleClose,
    customRef: ref,
  });

  function handleSelectFilter(value: T) {
    setValue(value);
    handleClose();
  }

  return (
    <motion.div
      className={`${styles.container} ${isInverted ? styles['container--inverted'] : ''} ${cardBg ? styles['container--card-bg'] : ''}`}
      ref={ref}
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {(loading &&
        (customComponents?.customSkeletonOption
          ? Array.from({ length: 10 }).map((_, index) => {
              return customComponents.customSkeletonOption?.(
                `load-more-options-select-${index}`,
              );
            })
          : Array.from({ length: 10 }).map((_, index) => {
              return (
                <div
                  className={skeleton.container}
                  key={`load-options-select-${index}`}
                >
                  <div className={skeleton.container__text} />
                </div>
              );
            }))) ||
        options.map((option, index) =>
          customComponents?.customOption ? (
            customComponents?.customOption({
              option,
              selected: getOptionValue(option) === value,
              handleSelect: handleSelectFilter,
              key: `select-input-option-${index}`,
            })
          ) : (
            <div
              className={`${styles.container__option} ${getOptionValue(option) === value ? styles['container__option--selected'] : ''}`}
              key={`select-input-option-${index}`}
              onClick={() => handleSelectFilter(option)}
            >
              {getOptionLabel(option)}
            </div>
          ),
        )}

      {loadingMore &&
        isAsync &&
        (customComponents?.customSkeletonOption
          ? Array.from({ length: 10 }).map((_, index) => {
              return customComponents.customSkeletonOption?.(
                `load-more-options-select-${index}`,
              );
            })
          : Array.from({ length: 10 }).map((_, index) => {
              return (
                <div
                  className={skeleton.container}
                  key={`load-more-options-select-${index}`}
                >
                  <div className={skeleton.container__text} />
                </div>
              );
            }))}
      {error && <ErrorCard error={error} refetch={refetch} isDefaultColor />}
    </motion.div>
  );
}

export default memo(SelectInputOptions) as typeof SelectInputOptions;
