import { TransProps } from 'next-translate';
import Trans from 'next-translate/Trans';

export function TransWithDefault({ components, ...props }: TransProps) {
  return (
    <Trans
      components={{
        strong: <strong />,
        br: <br />,
        p: <p />,
        ul: <ul />,
        li: <li />,
        ...components,
      }}
      {...props}
    />
  );
}
