import { FieldValues } from 'react-hook-form';
import { OptionsDrawerListProps } from './props';
import { useInfiniteScroll } from '@starsoft/common/hooks';
import { filterOptions } from '../../utils';
import { useMemo } from 'react';
import styles from './styles.module.scss';
import ListItem from './Item';
import EmptySearch from '@package/src/components/Empty';

export default function OptionsDrawerList<T extends FieldValues, V>({
  filterBy,
  options,
  hasNextPage = false,
  loadMore = () => {},
  loading = false,
  loadingMore = false,
  search,
  getOptionLabel,
  getOptionValue,
  setValue,
  customComponents,
  setOpen,
  isAsync,
  value,
  onSearchChange,
}: OptionsDrawerListProps<T, V>) {
  const { ref } = useInfiniteScroll({
    hasMore: hasNextPage,
    isLoading: loadingMore || loading,
    loadMore,
    condition: isAsync,
  });
  const filteredOptions = useMemo(
    () =>
      search && !onSearchChange
        ? filterOptions(options, search, filterBy)
        : options,
    [filterBy, options, search, onSearchChange],
  );

  const Items: JSX.Element[] = useMemo(
    () =>
      filteredOptions.map((option, index) => (
        <ListItem
          key={`autocomplete-menu-selector-option-${index}`}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          option={option}
          setOpen={setOpen}
          setValue={setValue}
          customComponents={customComponents}
          value={value}
        />
      )),
    [
      customComponents,
      filteredOptions,
      getOptionLabel,
      getOptionValue,
      setOpen,
      setValue,
      value,
    ],
  );
  const Skeletons: JSX.Element[] = useMemo(
    () =>
      Array.from({ length: 15 }).map((_, index) => {
        return (
          <ListItem
            key={`autocomplete-skeleton-menu-selector-option-${index}`}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            loading
            setOpen={setOpen}
            setValue={setValue}
            customComponents={customComponents}
            value={value}
          />
        );
      }),
    [
      customComponents,
      getOptionLabel,
      getOptionValue,
      setOpen,
      setValue,
      value,
    ],
  );

  if (filteredOptions.length === 0 && !loading && !loadingMore) {
    return (
      <div className={styles.container}>
        <EmptySearch search={search} />
      </div>
    );
  }

  return (
    <div className={styles.container} ref={ref}>
      {Items}
      {(loading || loadingMore) && isAsync && Skeletons}
    </div>
  );
}
