import { Image } from '@package/components';
import { SelectInputWithImageSkeletonProps } from './props';

import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';

export default function SelectInputWithImageSkeleton({
  cardBg,
}: SelectInputWithImageSkeletonProps) {
  return (
    <div
      className={`${styles.input} ${cardBg ? styles['input--card-bg'] : ''} `}
    >
      <div className={styles.input__wrapper}>
        <Image
          format="square"
          width={32}
          height={32}
          alt="coin-image"
          className={styles.input__image}
          secondarySkeleton
        />
        <div
          className={`${skeleton.text} ${cardBg ? skeleton['text--secondary'] : ''}`}
        />
        <div
          className={`${skeleton.chevron} ${cardBg ? skeleton['chevron--secondary'] : ''}`}
        />
      </div>
    </div>
  );
}
