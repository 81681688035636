import PaginationComponentSkeleton from './Skeleton';
import { getPaginationItems } from './helper';
import { NewPaginationProps } from './props';
import styles from './styles.module.scss';

export function PaginationComponent({
  currentPage,
  lastPage,
  maxLength,
  setCurrentPage,
  hasNextPage,
  hasPreviousPage,
  loading,
  fetchNextPage,
  fetchPreviousPage,
  secondaryStyles,
}: NewPaginationProps): JSX.Element {
  const pageNums = getPaginationItems(currentPage, lastPage, maxLength);

  if (loading) {
    return <PaginationComponentSkeleton secondaryStyles={secondaryStyles} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.container__content}>
        <button
          className={`${styles.container__content__page} ${secondaryStyles ? styles['container__content__page--secondary'] : ''}`}
          disabled={!hasPreviousPage}
          onClick={fetchPreviousPage}
        >
          <i className="fas fa-chevron-left" />
        </button>
        {pageNums.map((item: number, index) =>
          !isNaN(item) ? (
            <button
              className={`${styles.container__content__page} ${secondaryStyles ? styles['container__content__page--secondary'] : ''} ${item == currentPage ? styles['container__content__page--active'] : ''}`}
              key={`pagination-icon-skeleton-${item}+${index}`}
              onClick={() => setCurrentPage(item)}
            >
              {item}
            </button>
          ) : (
            '...'
          ),
        )}
        <button
          className={`${styles.container__content__page} ${secondaryStyles ? styles['container__content__page--secondary'] : ''}`}
          disabled={!hasNextPage}
          onClick={fetchNextPage}
        >
          <i className="fas fa-chevron-right" />
        </button>
      </div>
    </div>
  );
}
