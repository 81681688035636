import { Skeleton } from '@package/components';
import styled from 'styled-components';

export const CustomOptionSkeletonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
`;

export const ImageSkeleton = styled((props: any) => <Skeleton {...props} />)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const LabelSkeleton = styled((props: any) => <Skeleton {...props} />)`
  width: 100px;
  height: 16px;
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
`;
