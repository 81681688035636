import { useMemo } from 'react';
import styles from './styles.module.scss';
import { navigation } from '@/constants/navigation';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { IconButton, RichTooltip } from '@package/components';
import { useToggle } from '@package/hooks';

export default function LayoutHeaderMobileNavigation() {
  const [open, toggleOpen] = useToggle();
  const { t } = useTranslation('common');
  const { asPath } = useRouter();

  const Items = useMemo(
    () =>
      navigation.map(page => (
        <Link
          key={`navigation-header-${page.label}`}
          href={page?.path}
          className={`${styles.container__label} ${asPath.includes(page.path) ? styles['container__label--active'] : ''}`}
        >
          {t(page?.label)}
        </Link>
      )),
    [t, asPath],
  );

  return (
    <div className="mobile_only">
      <RichTooltip
        open={open}
        onClose={toggleOpen}
        content={<div className={styles.container}>{Items}</div>}
      >
        <IconButton onClick={toggleOpen}>
          <i className="fa-duotone  fa-bars" />
        </IconButton>
      </RichTooltip>
    </div>
  );
}
