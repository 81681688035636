import { CountrySelectorProps } from './props';
import { AutocompleteMenuSelector } from '../AutocompleteMenuSelector';
import {
  SelectInputWithImage,
  SelectOptionWithImage,
} from '@package/components';

export function CountrySelector({
  setValue,
  options,
  currentValue,
  isDisabled,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  loadMore,
  onSearchChange,
  isContainerColor,
}: CountrySelectorProps): JSX.Element {
  return (
    <AutocompleteMenuSelector
      options={options ?? []}
      getOptionLabel={e => e?.name}
      getOptionValue={e => e?.id}
      value={currentValue?.id}
      setValue={setValue}
      hasNextPage={hasNextPage}
      filterBy={['name']}
      onSearchChange={onSearchChange}
      isAsync
      loadMore={loadMore}
      isContainerColor={isContainerColor}
      isSmallerDrawer
      disabled={isDisabled}
      loading={isLoading}
      loadingMore={isFetchingNextPage}
      customComponents={{
        customOption: ({ handleSelect, option, selected }) => (
          <SelectOptionWithImage
            handleSelect={handleSelect}
            option={option}
            selected={selected}
            image={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/flags/${option?.image}`}
            label={option?.name}
          />
        ),
        customInput: ({ handleOpen, open, disabled, option }) => (
          <SelectInputWithImage
            handleOpen={handleOpen}
            image={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/flags/${option?.image}`}
            label={option?.dialCode}
            open={open}
            disabled={disabled}
          />
        ),
      }}
    />
  );
}
