import { useRouter } from 'next/router';
import { useMemo } from 'react';

export function useSearchParams(): URLSearchParams {
  const { query } = useRouter();

  const searchParams: URLSearchParams = useMemo(() => {
    return new URLSearchParams(query as Record<string, string>);
  }, [query]);

  return searchParams;
}
