import { PieLabelRenderProps } from 'recharts';
import { CustomText } from './styles';

const RADIAN = Math.PI / 180;

export function pieChartLabel({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  name,
}: PieLabelRenderProps) {
  const radius =
    Number(innerRadius) + (Number(outerRadius) - Number(innerRadius)) * 1.4;
  const x: number = Number(cx) + radius * Math.cos(-midAngle * RADIAN);
  const y: number = Number(cy) + radius * Math.sin(-midAngle * RADIAN);

  return (
    <CustomText
      x={x}
      y={y}
      fill="white"
      fontSize={11}
      textAnchor={x > Number(cx) ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${name}`}
    </CustomText>
  );
}
