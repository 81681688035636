import { FieldPath, FieldValues } from 'react-hook-form';
import { Order } from './props';

export function getValue<T extends FieldValues>(
  object: T,
  path: FieldPath<T>,
): any {
  const keys = path.split('.');
  let result = object;
  for (const key of keys) {
    result = result?.[key];
  }
  return result;
}

function getComparator<T extends FieldValues>(
  a: T,
  b: T,
  order: Order,
  orderBy: FieldPath<T>,
): number {
  const valueA = getValue(a, orderBy);
  const valueB = getValue(b, orderBy);

  if (valueA === undefined || valueB === undefined) {
    return 0;
  }

  let comparison = 0;

  if (valueA < valueB) {
    comparison = -1;
  } else if (valueA > valueB) {
    comparison = 1;
  }

  return order === Order.DESC ? comparison * -1 : comparison;
}

export function stableSort<T extends FieldValues>(
  array: T[],
  order: Order,
  orderBy: FieldPath<T> | null,
) {
  if (!orderBy) {
    return array;
  }

  return [...array].sort((a, b) => getComparator(a, b, order, orderBy));
}
