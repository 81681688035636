import { useState, useEffect } from 'react';

export const useAudio = (url: string, loop?: boolean) => {
  const [audio, setAudio] = useState<any>(null);
  const [playing, setPlaying] = useState<boolean>(false);

  const toggle = (value: boolean) => {
    setPlaying(value);
  };

  const handleAudioEffect = () => {
    if (audio) {
      audio.volume = 0.7;
      playing ? audio.play() : audio.pause();
    }
  };

  useEffect(handleAudioEffect, [playing, audio]);

  const handleComponentDidMount = () => {
    const handleEnd = () => {
      if (!loop) {
        setPlaying(false);
      }
    };

    const _audio = new Audio(url);

    setAudio(_audio);

    _audio.addEventListener('ended', handleEnd);
    _audio.loop = loop == true;

    return () => {
      _audio.removeEventListener('ended', () => setPlaying(false));
      _audio.pause();
      _audio.currentTime = 0;
    };
  };

  useEffect(handleComponentDidMount, [loop, url]);

  return [playing, toggle] as const;
};
