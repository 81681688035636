import { TableGridContentProps } from './props';
import { useMemo } from 'react';
import { stableSort } from '../helpers';
import { FieldValues } from 'react-hook-form';
import styles from '../styles.module.scss';
import { TableCell } from '@mui/material';
import TableGridRow from './Row';
import TableGridRowSkeleton from './Row/Skeleton';
import { NoDataComponent } from '@package/components';

export default function TableGridContent<T extends FieldValues>({
  columns,
  rows,
  isLoading,
  order,
  orderBy,
  useLargerRows,
  isSecondaryStyles,
}: TableGridContentProps<T>) {
  const Rows = useMemo(
    () =>
      stableSort(rows, order, orderBy)?.map((row, index) => (
        <TableGridRow
          row={row}
          index={index}
          columns={columns}
          useLargerRows={useLargerRows}
          isLoading={isLoading}
          isSecondaryStyles={isSecondaryStyles}
          key={`table-grid-row-${row?.id ? row?.id : index}`}
        />
      )),
    [
      rows,
      order,
      orderBy,
      columns,
      isSecondaryStyles,
      isLoading,
      useLargerRows,
    ],
  );

  const Skeletons = useMemo(
    () =>
      Array.from({ length: 8 }).map((_, index) => (
        <TableGridRowSkeleton
          index={index}
          columns={columns}
          isSecondaryStyles={isSecondaryStyles}
          key={`table-grid-row-skeleton-${index}`}
        />
      )),
    [isSecondaryStyles, columns],
  );

  if (rows?.length == 0 && !isLoading) {
    return (
      <tr className={styles['container__table-container__body__row']}>
        <TableCell
          className={styles['container__table-container__body__row__mui-cell']}
          colSpan={columns.length}
        >
          <NoDataComponent tableStyles isSecondaryStyles isSmall />
        </TableCell>
      </tr>
    );
  }

  return isLoading ? (
    Skeletons
  ) : isSecondaryStyles ? (
    <>
      {Rows}
      {Rows}
    </>
  ) : (
    Rows
  );
}
