import { PropsWithChildren } from 'react';
import { ButtonProps } from './props';

import styles from './styles.module.scss';

export function Button({
  visible = true,
  children,
  disabled,
  isloading,
  isSecondary,
  isSmall,
  isSkeleton,
  isErrorButton,
  isTiny,
  textColor,
  className = '',
  isLinkButton,
  useMaxContent,
  icon,
  skeletonDefault,
  style,
  dotted,
  ...props
}: PropsWithChildren<ButtonProps>) {
  if (!visible) {
    return;
  }

  if (isSkeleton) {
    return (
      <div
        className={`${skeletonDefault ? `${styles['button__skeleton-default']} ${isSmall ? styles['button__skeleton-default--isSmall'] : ''}` : `${styles.button__skeleton} ${isSmall ? styles['button__skeleton--isSmall'] : ''}`} ${className}`}
      />
    );
  }

  return (
    <button
      {...props}
      className={`${styles.button} ${isSecondary ? styles['button--secondary'] : ''} ${isSmall ? styles['button--isSmall'] : ''} ${isTiny ? styles['button--isTiny'] : ''} ${isErrorButton ? styles['button--isErrorButton'] : ''} ${isLinkButton ? styles['button--isLinkButton'] : ''} ${useMaxContent ? styles['button--useMaxContent'] : ''} ${dotted ? styles['button--dotted'] : ''} ${className}`}
      disabled={isloading || disabled}
      style={style}
    >
      {(icon || isloading) && (
        <i
          className={`${isloading ? `${styles.button__spinner} fas fa-circle-notch fa-spin` : ''} ${icon && !isloading ? icon : ''} `}
        />
      )}
      {children}
    </button>
  );
}
