import { memo } from 'react';
import { TabProps } from './props';
import styles from './styles.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { ToolTip } from '@package/components';

function Tab<T>({
  currentTab,
  setTab,
  tab,
  i18nNamespace,
  isSmall,
  useMaxContent,
  useContainer,
  showIndicator,
  isTiny,
  disabled,
}: TabProps<T>) {
  const { t } = useTranslation(!!i18nNamespace ? i18nNamespace : 'common');
  const { t: tCommon } = useTranslation('common');
  const isActive = currentTab === tab?.value;

  function handleClick() {
    if (tab?.inactive) {
      return;
    }

    setTab(tab?.value);
  }

  return (
    <ToolTip
      visible={!!tab?.inactive}
      titleComponent={tCommon('option_unavailable')}
    >
      <button
        className={`${styles.button} ${isTiny ? styles['button--tiny'] : ''} ${isActive ? styles['button--active'] : ''} ${isSmall ? styles['button--small'] : ''} ${isActive && isSmall ? styles['button--small--active'] : ''} ${useContainer ? styles['button--container'] : ''} ${!useMaxContent ? styles['button--max-content'] : ''} ${showIndicator ? styles['button--with-indicator'] : ''} ${tab?.inactive ? styles['button--disabled'] : ''}`}
        onClick={handleClick}
        type="button"
        id={`tab-button-${tab?.value}`}
        disabled={disabled}
      >
        <div className={styles.button__content}>
          {tab?.icon && <i className={tab?.icon} />}
          {t(tab?.label, {
            app_provider: process.env.NEXT_PUBLIC_BET_NAME,
          })}
        </div>
      </button>
    </ToolTip>
  );
}

export default memo(Tab) as typeof Tab;
