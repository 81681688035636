import { SelectInputWithImage } from '@starsoft/common/src/components/SelectInput/SelectWithImage/SelectInputWithImage';
import SelectInputWithImageSkeleton from '@starsoft/common/src/components/SelectInput/SelectWithImage/SelectInputWithImage/Skeleton';
import { useMemo } from 'react';
import { CoinPickerProps } from './props';
import { Label, LabelSkeleton, FormGroup } from './styles';
import { Coin, CoinType } from '@package/models';
import AutocompleteSelectorOptionWithImageSkeleton from '../AutocompleteMenuSelector/CustomOptions/AutocompleteSelectorAccount/Skeleton';
import { AutocompleteMenuSelector } from '../AutocompleteMenuSelector';
import { AutocompleteSelectorOptionCoinWithImage } from '@package/components';

export function CoinPicker({
  isLoading,
  disabled,
  setCoin,
  label,
  modalTitle,
  coins,
  currentCoinId,
}: CoinPickerProps) {
  const loading = isLoading || currentCoinId === null;
  const options = useMemo(
    () => coins?.filter(item => item?.type != CoinType.Demo),
    [coins],
  );

  function handleSelect(coin: Coin) {
    setCoin?.(coin);
  }

  return (
    <FormGroup>
      {loading ? <LabelSkeleton /> : <Label>{label}</Label>}
      <AutocompleteMenuSelector
        disabled={disabled}
        title={modalTitle ?? ''}
        options={options as Coin[]}
        value={currentCoinId}
        filterBy={['symbol', 'name']}
        setValue={handleSelect}
        getOptionLabel={option => option.symbol}
        getOptionValue={option => option.id}
        loading={loading}
        customComponents={{
          customOption: ({ handleSelect, option, selected }) => (
            <AutocompleteSelectorOptionCoinWithImage
              handleSelect={handleSelect}
              option={option}
              selected={selected}
            />
          ),
          customInput: ({ handleOpen, open, option }) => (
            <SelectInputWithImage
              handleOpen={handleOpen}
              open={open}
              image={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/coins/rounded/${option?.image}`}
              label={option?.symbol ?? ''}
              disabled={disabled}
            />
          ),
          customSkeletonOption: () => (
            <AutocompleteSelectorOptionWithImageSkeleton />
          ),

          customSkeletonInput: () => <SelectInputWithImageSkeleton />,
        }}
      />
    </FormGroup>
  );
}
