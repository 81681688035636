import { PaginatedResponse } from '@package/models';
import { InfiniteData, QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

interface BaseEntity {
  id: number | string;
}

export function useUpdateInfiniteQuery<T extends BaseEntity>(
  queryKey: QueryKey,
): (...records: T[]) => void {
  const queryClient = useQueryClient();

  const updatePages: (
    pages: PaginatedResponse<T>[],
    records: T[],
  ) => PaginatedResponse<T>[] = useCallback(
    (pages: PaginatedResponse<T>[], records: T[]): PaginatedResponse<T>[] => {
      return pages.map((page: PaginatedResponse<T>) => {
        const recordMap: Map<number | string, T> = new Map<number | string, T>(
          page.data.map(record => [record.id, record]),
        );

        records.forEach((newRecord: T) => {
          if (!recordMap.has(newRecord.id)) {
            return;
          }

          recordMap.set(newRecord.id, newRecord);
        });

        return {
          ...page,
          data: Array.from(recordMap.values()),
        };
      });
    },
    [],
  );

  const updateQueriesData: (
    records: T[],
    cachedPages?: InfiniteData<PaginatedResponse<T>>,
  ) => InfiniteData<PaginatedResponse<T>, unknown> | undefined = useCallback(
    (
      records: T[],
      cachedPages?: InfiniteData<PaginatedResponse<T>>,
    ): InfiniteData<PaginatedResponse<T>, unknown> | undefined => {
      if (!cachedPages || !records) {
        return cachedPages;
      }

      const updatedPages: PaginatedResponse<T>[] = updatePages(
        cachedPages.pages,
        records,
      );

      return {
        ...cachedPages,
        pages: updatedPages,
      };
    },
    [updatePages],
  );

  const updateInfiniteQuery: (...records: T[]) => void = useCallback(
    (...records: T[]): void => {
      queryClient.setQueriesData<InfiniteData<PaginatedResponse<T>>>(
        {
          queryKey,
        },
        pages => updateQueriesData(records, pages),
      );
    },
    //eslint-disable-next-line
    [queryClient, updateQueriesData],
  );

  return updateInfiniteQuery;
}
