import React, { PropsWithChildren, useMemo } from 'react';
import { SliderContentProps } from '../props';
import styles from './styles.module.scss';
import SliderStepper from './Stepper';
import { useIsMobile } from '@package/hooks';

export default function SliderContent({
  children,
  showButtonsOnScroll,
  size,
  containerProps,
  hideButtons,
  absoluteButton,
  mobileSize,
}: PropsWithChildren<SliderContentProps>) {
  const {
    containerRef,
    handleMouseDown,
    handleMouseLeave,
    handleMouseMove,
    currentStep,
    onStepClick,
    handleMouseUp,
    hasScroll,
    isDragging,
    steps,
  } = containerProps ?? {};

  const isMobile: boolean = useIsMobile();
  const itemsPerView: number = isMobile && mobileSize ? mobileSize : size;

  const isScrollable: boolean =
    !showButtonsOnScroll || (showButtonsOnScroll && hasScroll);

  const Sections = useMemo(
    () =>
      (isMobile && !mobileSize) || !size
        ? children
        : React.Children.toArray(children).map((child, idx) => (
            <div className={styles.container__item} key={`slider-item-${idx}`}>
              {child}
            </div>
          )),
    [isMobile, children, size, mobileSize],
  );

  return (
    <div
      className={styles.container}
      style={
        {
          '--items-per-view': itemsPerView,
        } as React.CSSProperties
      }
    >
      <div
        className={`${styles.container__content} ${isDragging ? styles['container__content--dragging'] : ''}`}
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        dir="ltr"
      >
        {Sections ?? children}
      </div>
      {!hideButtons && isScrollable && steps > 1 && (
        <SliderStepper
          currentStep={currentStep as number}
          onStepClick={onStepClick!}
          steps={steps}
          absolute={absoluteButton}
        />
      )}
    </div>
  );
}
