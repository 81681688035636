import { FieldValues } from 'react-hook-form';
import { TableWithPaginationProps } from './props';
import styles from './styles.module.scss';
import { ErrorCard } from '../ErrorCard';
import { TableGrid } from '../TableGrid';
import { PaginationComponent } from '../PaginationComponent';

export function TableWithPagination<T extends FieldValues>({
  columns,
  data,
  error,
  fetchNextPage,
  fetchPreviousPage,
  isLoading,
  page,
  refetch,
  isSecondaryStyles,
  setCurrentPage,
  ...props
}: TableWithPaginationProps<T>) {
  if (error) {
    return <ErrorCard error={error} refetch={refetch} />;
  }

  return (
    <div className={styles.container}>
      <TableGrid
        rows={data?.data ?? []}
        columns={columns}
        isLoading={isLoading}
        isSecondaryStyles={isSecondaryStyles}
        {...props}
      />

      <PaginationComponent
        currentPage={page}
        hasPreviousPage={data?.metadata?.hasPreviousPage ?? false}
        hasNextPage={data?.metadata?.hasNextPage ?? false}
        loading={isLoading}
        lastPage={data?.metadata?.pageCount ?? 0}
        setCurrentPage={setCurrentPage}
        fetchNextPage={fetchNextPage}
        fetchPreviousPage={fetchPreviousPage}
        maxLength={7}
        secondaryStyles
      />
    </div>
  );
}
