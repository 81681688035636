import styled from 'styled-components';
import { CircularProgressProps } from './props';
import Color from 'color';
import { getComputedColor } from '@package/src/utils/theme';

export const CircularProgress = styled.div<CircularProgressProps>`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background: conic-gradient(
    ${({ progress, pathColor, theme, trailColor }) =>
      `${
        trailColor
          ? trailColor
          : Color(getComputedColor(theme.vars.palette.primary.main))
              .alpha(0.5)
              .toString()
      }  ${progress}%, 0, ${
        pathColor ? pathColor : theme.vars.palette.primary.main
      }  ${100 - Math.ceil(progress)}%`}
  );
`;
