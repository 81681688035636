import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import { Button } from '../Button';

export function BackButton({
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const { t } = useTranslation('common');

  return (
    <div className={styles.container}>
      <Button isTiny isSecondary useMaxContent {...props}>
        <div className={styles.container__row}>
          <i className="fa-solid fa-arrow-left" />
          {t('back_button')}
        </div>
      </Button>
    </div>
  );
}
