import { ProgressProps } from './props';
import styles from './styles.module.scss';

export function Progress({
  progress,
  color,
  isLoading,
  size,
  useCardColor,
  useContainerColor,
}: ProgressProps) {
  if (isLoading) {
    return (
      <div
        className={`${styles.container} ${useContainerColor ? styles['container--useContainerColor'] : ''} ${styles[`container--${size}`]}`}
      >
        <div className={styles.container__progress__skeleton} />
      </div>
    );
  }
  return (
    <div
      className={`${styles.container} ${useContainerColor ? styles['container--useContainerColor'] : ''} ${useCardColor ? styles['container--card-color'] : ''} ${styles[`container--${size}`]}`}
    >
      <div
        className={`${styles.container__progress} ${color ? styles['container__progress--customColor'] : ''}`}
        style={
          color
            ? //@ts-ignore
              { '--customColor': color, width: `${progress}%` }
            : { width: `${progress}%` }
        }
      />
    </div>
  );
}
