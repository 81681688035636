import { FieldValues } from 'react-hook-form';
import OptionsDrawerHeader from './Header';
import { OptionsDrawerProps } from './props';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import OptionsDrawerList from './List';
import { m } from 'framer-motion';
import { containerVariants } from '../variants';
import { useClickOutside } from '@starsoft/common/hooks';

export default function OptionsDrawer<T extends FieldValues, V>({
  filterBy,
  options,
  setOpen,
  title,
  hasNextPage,
  isContainerColor,
  loadMore,
  loading,
  loadingMore,
  getOptionLabel,
  getOptionValue,
  setValue,
  customComponents,
  isAsync,
  value,
  isSmallerDrawer,
  hideSearch,
  onSearchChange,
}: OptionsDrawerProps<T, V>) {
  const [search, setSearch] = useState<string>('');
  const ref = useClickOutside({
    open: true,
    onClickOutside: () => setOpen(false),
  });

  function onSetSearch(search: string) {
    setSearch(search);

    if (onSearchChange) {
      onSearchChange(search);
    }
  }

  function onUnmount() {
    return () => {
      setSearch('');
      if (onSearchChange) {
        onSearchChange('');
      }
    };
  }

  useEffect(onUnmount, [onSearchChange]);

  return (
    <m.div
      className={`${styles.container} ${isContainerColor ? styles['container--default'] : ''} ${isSmallerDrawer ? styles['container--smaller'] : ''}`}
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
      ref={ref}
    >
      <OptionsDrawerHeader
        search={search}
        setSearch={onSetSearch}
        setOpen={setOpen}
        title={title}
        isContainerColor={isContainerColor}
        loadingMore={loadingMore}
        hideSearch={hideSearch}
        customComponents={customComponents}
      />

      <OptionsDrawerList
        filterBy={filterBy}
        options={options}
        hasNextPage={hasNextPage}
        loadMore={loadMore}
        loading={loading}
        loadingMore={loadingMore}
        search={search}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        setOpen={setOpen}
        setValue={setValue}
        customComponents={customComponents}
        isAsync={isAsync}
        value={value}
        onSearchChange={onSearchChange}
      />
    </m.div>
  );
}
