import { getComputedColor } from '@package/src/utils/theme';
import Color from 'color';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.vars.palette.background.default};
  border: 1px solid
    ${({ theme }) =>
      Color(getComputedColor(theme.vars.palette.text.secondary))
        .alpha(0.1)
        .toString()};
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
  padding: 16px;
  width: 100%;
`;

export const CustomText = styled.text`
  fill: ${({ theme }) => theme.vars.palette.text.primary};
`;
