import {
  ResponsiveContainer,
  Tooltip,
  BarChart as RechartsBarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
} from 'recharts';
import { BarChartProps } from './props';
import { useTheme } from 'styled-components';
import { useMemo } from 'react';
import { BarChartTooltip } from './Tooltip';

export function BarChart<T>({ data, dataKey, showAxis }: BarChartProps<T>) {
  const theme = useTheme();

  const Cells = useMemo(
    () =>
      data?.map((entry, index) => {
        return (
          <Cell
            key={`cell-${index}`}
            fill={
              Number(entry?.amount) < 0
                ? theme.vars.palette.error.light
                : theme.vars.palette.success.main
            }
            strokeWidth={1}
          />
        );
      }),
    [data, theme],
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RechartsBarChart
        data={data}
        margin={{ left: 0, right: 0, bottom: 0, top: 0 }}
        {...{ overflow: 'visible' }}
      >
        <Bar dataKey={dataKey.line}>{Cells}</Bar>
        {showAxis && (
          <>
            <XAxis
              dataKey={dataKey.x}
              tick={{
                stroke: theme.vars.palette.text.secondary,
                strokeWidth: 0,
                fontSize: '12px',
                fontWeight: '400',
              }}
            />
            <YAxis
              dataKey={dataKey.y}
              width={30}
              tick={{
                stroke: theme.vars.palette.text.secondary,
                strokeWidth: 0,
                fontSize: '12px',
                fontWeight: '400',
              }}
            />
          </>
        )}
        <Tooltip cursor={false} content={<BarChartTooltip />} />
      </RechartsBarChart>
    </ResponsiveContainer>
  );
}
