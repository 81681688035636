import { useEffect, useState, useRef, useCallback } from 'react';

export const useIntersectionObserver = (
  threshold = 0,
): [
  boolean,
  React.RefObject<HTMLDivElement>,
  React.RefObject<HTMLDivElement>,
] => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [hasRendered, setHasRendered] = useState(false);
  const [count, setCount] = useState(0);

  const targetRef = useRef<HTMLDivElement>(null);
  const bufferRef = useRef<HTMLDivElement>(null);

  const updateIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      setIsIntersecting(entry.isIntersecting);

      if (entry.isIntersecting && !hasRendered) {
        if (count > 1) {
          setHasRendered(true);
          return;
        }

        setCount(prevCount => prevCount + 1);
      }
    },
    [count, hasRendered],
  );

  const handleObserve = useCallback(() => {
    const observer = new IntersectionObserver(updateIntersection, {
      threshold,
      rootMargin: '100px 0px 100px 0px',
    });

    const bufferElement = bufferRef.current;
    if (bufferElement) {
      observer.observe(bufferElement);
    }

    return () => {
      if (bufferElement) {
        observer.unobserve(bufferElement);
      }
    };
  }, [threshold, updateIntersection]);

  useEffect(handleObserve, [threshold, handleObserve]);

  return [isIntersecting || hasRendered, targetRef, bufferRef];
};
