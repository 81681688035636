import Head from 'next/head';
import { PreloadImagesProps } from './props';
import { useMemo } from 'react';

export function PreloadImages({ images }: PreloadImagesProps) {
  const Images: JSX.Element[] = useMemo(
    () =>
      images.map(img => (
        <link
          rel="preload"
          href={img}
          as="image"
          fetchPriority="high"
          key={`preload-${img}`}
        />
      )),
    [images],
  );

  return <Head>{Images}</Head>;
}
