import { useCallback, useEffect } from 'react';
import { UseInfiniteDocumentScrollProps } from './props';
import { debounce } from 'lodash';

export function useInfiniteDocumentScroll({
  hasMore,
  isLoading,
  loadMore,
  condition = true,
}: UseInfiniteDocumentScrollProps) {
  //eslint-disable-next-line
  const scrollListener = useCallback(
    debounce(() => {
      const shouldLoadMore =
        window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 600 &&
        hasMore &&
        !isLoading &&
        condition;

      if (shouldLoadMore) {
        loadMore();
      }
    }, 100),
    [hasMore, loadMore, isLoading, condition],
  );

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', scrollListener);

      return () => {
        window.removeEventListener('scroll', scrollListener);
      };
    }
  }, [scrollListener]);
}
