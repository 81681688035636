import useTranslation from 'next-translate/useTranslation';
import { TableGridHeaderProps } from '../props';
import { FieldValues } from 'react-hook-form';
import styles from '../styles.module.scss';
import TableHeadSortLabel from './SortLabel';

function EnhancedTableHead<T extends FieldValues>({
  columns,
  handleOrder,
  order,
  orderBy,
  isSecondaryStyles,
  preventOrder,
}: TableGridHeaderProps<T>) {
  const { t } = useTranslation('data-grid');

  return (
    <thead>
      <tr
        className={`${styles['container__table-container__head']} ${isSecondaryStyles ? styles['container__table-container__head--secondary'] : ''}`}
      >
        {columns.map((column, i) => (
          <td
            className={`${styles['container__table-container__cell']} ${styles['container__table-container__cell--head']} ${column?.alignEnd ? styles['container__table-container__cell--align-end'] : ''} ${column?.alignCenter ? styles['container__table-container__cell--align-center'] : ''} ${preventOrder ? styles['container__table-container__cell--head--disabled'] : ''}`}
            key={`table_cell_head_${i}`}
          >
            {column?.renderHeaderCell ? (
              column?.renderHeaderCell()
            ) : (
              <TableHeadSortLabel
                active={orderBy == column.field}
                direction={orderBy === column.field ? order : 'asc'}
                onClick={() => handleOrder(order, column.field)}
              >
                {t(column.headerName)}
              </TableHeadSortLabel>
            )}
          </td>
        ))}
      </tr>
    </thead>
  );
}

export default EnhancedTableHead;
