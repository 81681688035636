import { useEffect, useLayoutEffect, useState } from 'react';

function getMatches(query: string): boolean {
  if (typeof window !== 'undefined') {
    return window.matchMedia(query).matches;
  }
  return false;
}

export function useMediaQuery(query: string): boolean | undefined {
  const [hasMatch, setHasMatches] = useState<boolean>(getMatches(query));
  const [initialLoad, setInitialLoad] = useState(true);

  useLayoutEffect(() => {
    if (!initialLoad) {
      return;
    }

    setInitialLoad(false);
  }, [initialLoad]);

  function handleChange() {
    setHasMatches(getMatches(query));
  }

  function handleWindowChange() {
    const matchMedia = window.matchMedia(query);
    handleChange();
    matchMedia.addEventListener('change', handleChange);
    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
  }

  useEffect(
    handleWindowChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query],
  );

  return initialLoad ? undefined : hasMatch;
}
