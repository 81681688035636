import styled from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.vars.palette.text.secondary};

  & i {
    margin-left: 8px;
    margin-right: 16px;
  }
`;
