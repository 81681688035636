import styles from './styles.module.scss';

export default function CloseButton({
  className,

  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      className={`${styles.button} ${className ? className : ''}`}
      {...props}
      type="button"
    >
      <i className="fa-solid fa-xmark" />
    </button>
  );
}
