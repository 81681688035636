import { useCallback, useEffect, useRef } from 'react';
import { ValidateCodeInputProps } from './props';
import styles from './styles.module.scss';
import { KeyboardKey } from '@package/enums';

export function ValidateCodeInput({
  setValue,
  maxLength = 6,
  isContainerColor,
  isLoading,
  withMaxWidth,
  isDefaultColor,
}: ValidateCodeInputProps) {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, maxLength);
  }, [maxLength]);

  const handleSetValue = useCallback(() => {
    const value = inputRefs.current
      .map(input => input?.value ?? '')
      .filter(value => value !== '')
      .join('');

    setValue(value);
  }, [setValue]);

  function handleArrowLeft(e: any) {
    const previousInput = e.target.previousElementSibling as HTMLInputElement;
    if (!previousInput) return;
    setTimeout(() => {
      previousInput.selectionStart = previousInput.selectionEnd =
        previousInput.value.length;
      previousInput.focus();
    }, 0);
  }

  function handleArrowRight(e: any) {
    const nextInput = e.target.nextElementSibling as HTMLInputElement;
    if (!nextInput) return;
    nextInput.focus();
  }

  function handleChange(e: any) {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    handleSetValue();

    if (Object.values(KeyboardKey).includes(e?.code) || !e.target.value) {
      return;
    }

    handleArrowRight(e);
  }

  function handleBackspace(e: any) {
    const input = e.target;

    if (input.value !== '') {
      return;
    }

    if (!input?.previousElementSibling) return;
    input.previousElementSibling.focus();
  }

  const handlePaste = useCallback(
    (e: any) => {
      e.preventDefault();
      const paste = e.clipboardData.getData('text')?.replace(/[^0-9]/g, '');
      inputRefs.current.forEach((input, i) => {
        if (!input) {
          return;
        }

        input.value = paste[i] || '';
      });

      handleSetValue();
    },
    [handleSetValue],
  );
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    switch (e.code) {
      case KeyboardKey.BACKSPACE:
        handleBackspace(e);
        break;
      case KeyboardKey.ARROW_LEFT:
        handleArrowLeft(e);
        break;
      case KeyboardKey.ARROW_RIGHT:
        handleArrowRight(e);
        break;
      default:
    }
  }, []);

  function handleMount() {
    inputRefs.current.forEach(input => {
      input?.addEventListener('paste', handlePaste);
      input?.addEventListener('keydown', handleKeyDown);
    });
  }

  useEffect(handleMount, [handlePaste, inputRefs, handleKeyDown]);

  if (isLoading) {
    return (
      <div
        className={styles.container}
        style={{
          gridTemplateColumns: `repeat(${maxLength}, auto)`,
        }}
      >
        {Array.from({ length: maxLength }).map((_, index) => (
          <div
            key={index}
            className={`${styles.container__input__skeleton} ${withMaxWidth ? styles['container__input--max-width'] : ''}`}
          />
        ))}
      </div>
    );
  }

  return (
    <div
      className={styles.container}
      style={{
        gridTemplateColumns: `repeat(${maxLength}, auto)`,
      }}
    >
      {Array.from({ length: maxLength }).map((_, index) => (
        <input
          key={index}
          type="text"
          className={`${styles.container__input} ${isContainerColor ? styles['container__input--container-color'] : ''} ${isDefaultColor ? styles['container__input--default-color'] : ''} ${withMaxWidth ? styles['container__input--max-width'] : ''}`}
          onChange={handleChange}
          maxLength={1}
          ref={el => (inputRefs.current[index] = el)}
        />
      ))}
    </div>
  );
}
