import { Image, Label, Option } from './styles';
import { AutocompleteSelectorOptionWithImageProps } from './props';

export function AutocompleteSelectorOptionWithImage<T>({
  image,
  label,
  selected,
  handleSelect,
  option,
}: AutocompleteSelectorOptionWithImageProps<T>) {
  return (
    <Option selected={selected} onClick={() => handleSelect(option)}>
      <Image
        src={image}
        alt={`label-${label}`}
        format="square"
        width={32}
        height={32}
        secondarySkeleton
      />
      <Label>{label}</Label>
    </Option>
  );
}
