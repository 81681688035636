import {
  ResponsiveContainer,
  Tooltip,
  PieChart as RechartsPieChart,
  Pie,
  Cell,
} from 'recharts';
import { PieChartProps } from './props';
import { useTheme } from 'styled-components';
import PieChartTooltip from './Tooltip';
import { pieChartLabel } from './label';

export function PieChart<T>({ data, dataKey, showAxis }: PieChartProps<T>) {
  const theme = useTheme();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RechartsPieChart
        margin={{ left: 0, right: 0, bottom: 0, top: 0 }}
        {...{ overflow: 'visible' }}
      >
        <Pie
          data={data}
          dataKey={dataKey.y as string}
          nameKey={dataKey.x as string}
          cx="50%"
          cy="50%"
          outerRadius={80}
          labelLine={true}
          label={pieChartLabel}
          stroke="transparent"
        >
          {data.map((item, index) => (
            <Cell
              key={`pie-slice-${index}`}
              fill={item?.color ?? theme.vars.palette.primary.main}
            />
          ))}
        </Pie>
        <Tooltip cursor={false} content={<PieChartTooltip />} />
      </RechartsPieChart>
    </ResponsiveContainer>
  );
}
