import { OptionsDrawerHeaderProps } from './props';
import styles from './styles.module.scss';
import { Input, ModalHeader } from '@starsoft/common/components';
import { FieldValues } from 'react-hook-form';

export default function OptionsDrawerHeader<T extends FieldValues, V>({
  search,
  setSearch,
  setOpen,
  title,
  isContainerColor,
  hideSearch,
  customComponents,
}: OptionsDrawerHeaderProps<T, V>) {
  function onClose() {
    setOpen(false);
  }

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  return (
    <div className={styles.container}>
      <ModalHeader title={title} onClose={onClose} smallPadding />

      {!hideSearch &&
        (!!customComponents?.customSearchInput ? (
          customComponents?.customSearchInput()
        ) : (
          <div className={styles.container__padded}>
            <Input
              type="text"
              value={search}
              isContainerColor={isContainerColor}
              onChange={handleSearch}
              icon="fad fa-search"
            />
          </div>
        ))}
    </div>
  );
}
