import { CardProps } from './props';
import {
  Button,
  Chip,
  Column,
  Container,
  ContentContainer,
  Description,
  ImageBackground,
  Title,
} from './styles';

export default function SwipperCard({
  description,
  image,
  title,
  action,
  linkLabel,
  chipText,
  link,
}: CardProps) {
  return (
    <Container>
      <Chip>{chipText}</Chip>
      <ContentContainer>
        <Column>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Column>
        <Button onClick={() => action?.()} href={link}>
          {linkLabel}
        </Button>
      </ContentContainer>

      <ImageBackground
        format="square"
        width={1000}
        height={500}
        src={image}
        secondarySkeleton
      />
    </Container>
  );
}
