import { FieldValues } from 'react-hook-form';
import { SelectedOptionProps } from './props';
import { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { Input } from '@package/components';

export default function SelectedOption<T extends FieldValues, V>({
  value,
  disabled,
  options,
  getOptionValue,
  getOptionLabel,
  customComponents,
  open,
  isContainerColor,
  setOpen,
  loading,
}: SelectedOptionProps<T, V>) {
  const [selectedOption, setSelectedOption] = useState<T | undefined>();
  const foundOption: T | undefined = useMemo(
    () =>
      options?.find(option => {
        return getOptionValue(option) === value;
      }),
    [getOptionValue, options, value],
  );
  const selectedLabel: string = selectedOption
    ? getOptionLabel(selectedOption)
    : '';

  function onChangeOption() {
    if (!foundOption) {
      return;
    }

    setSelectedOption(foundOption);
  }

  useEffect(onChangeOption, [foundOption]);

  function toggle() {
    setOpen(prev => !prev);
  }

  if (loading) {
    if (!!customComponents?.customSkeletonInput) {
      return customComponents?.customSkeletonInput();
    }

    return <Input isLoading isContainerColor={isContainerColor} />;
  }

  if (!!customComponents?.customInput) {
    return customComponents.customInput({
      handleOpen: toggle,
      open,
      option: selectedOption,
    });
  }

  return (
    <div
      className={`${styles.container} ${disabled ? styles['container--disabled'] : ''} ${
        isContainerColor ? styles['container--container-color'] : ''
      }`}
      onClick={toggle}
    >
      <p>{selectedLabel}</p>
      <i className="fas fa-chevron-down" />
    </div>
  );
}
