import { useCustomCarousel } from '@starsoft/common/hooks';
import { SwipperProps } from './props';
import {
  ButtonLeft,
  ButtonRight,
  CarouselContainer,
  Container,
} from './styles';
import SwipperCard from './Card';

export function Swipper({ cards }: SwipperProps) {
  const {
    containerRef,
    handleDragLeft,
    handleDragRight,
    hasScrollLeft,
    hasScrollRight,
    handleMouseDown,
    handleMouseLeave,
    handleMouseMove,
    handleMouseUp,
    isDragging,
  } = useCustomCarousel();

  function dragLeft() {
    handleDragLeft(318);
  }

  function dragRight() {
    handleDragRight(318);
  }

  return (
    <Container>
      {hasScrollLeft && (
        <ButtonLeft onClick={dragLeft}>
          <i className="fas fa-chevron-left" />
        </ButtonLeft>
      )}

      <CarouselContainer
        length={cards.length}
        ref={containerRef}
        isDragging={isDragging}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        {cards.map((card, i) => (
          <SwipperCard key={`swipper-card-${i}`} {...card} />
        ))}
      </CarouselContainer>

      {hasScrollRight && (
        <ButtonRight onClick={dragRight}>
          <i className="fas fa-chevron-right" />
        </ButtonRight>
      )}
    </Container>
  );
}
