import { useCallback, useEffect, useState } from 'react';
import { UseExternalScriptOptios } from './props';

export function useExternalScript({
  src,
  callback,
  type,
}: UseExternalScriptOptios) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const reset = useCallback(() => {
    setIsLoading(true);
    setIsError(false);
    setIsReady(false);
  }, []);
  const onLoad = useCallback(() => {
    setIsLoading(false);
    setIsReady(true);
    callback?.();
    //eslint-disable-next-line
  }, []);

  const onError = useCallback(() => {
    setIsLoading(false);
    setIsError(true);
  }, []);

  const removeScript = useCallback(
    (script: HTMLElement) => {
      script.removeEventListener('load', onLoad);
      script.removeEventListener('error', onError);
      document.body.removeChild(script);
    },
    [onError, onLoad],
  );

  function handleExternalScript() {
    let script = document.querySelector(`script[src="${src}"]`) as any;

    if (!script) {
      script = document.createElement('script');
      script.type = type ?? 'application/javascript';
      script.src = src;
      document.body.appendChild(script);
    }

    script.addEventListener('load', onLoad);
    script.addEventListener('error', onError);

    return () => {
      removeScript(script);
    };
  }

  useEffect(handleExternalScript, [src, onLoad, onError, type, removeScript]);

  function retry() {
    reset();
    let script = document.querySelector(`script[src="${src}"]`) as any;

    if (script) {
      removeScript(script);
    }

    handleExternalScript();
  }

  return {
    isLoading,
    isError,
    isReady,
    retry,
  };
}
