import {
  BalanceContainer,
  CoinName,
  CoinSymbol,
  Column,
  Image,
  Option,
  Row,
} from './styles';
import { AutocompleteSelectorOptionWithImageProps } from './props';
import useTranslation from 'next-translate/useTranslation';
import { WalletService } from '../../../../services';
import { CoinID } from '../../../../enums';
import { ToolTip } from '../../../Tooltip';
import { coinFormattingMapping } from '../../../../constants';

export function AutocompleteSelectorAccount({
  option,
  selected,
  handleSelect,
  isInvestmentWallet,
}: AutocompleteSelectorOptionWithImageProps) {
  const { t } = useTranslation('common');
  const decimal: string =
    coinFormattingMapping?.get(option?.coin?.id as number)?.decimal ?? '';
  const formattedValue = WalletService.maskCurrency({
    value: option?.balance,
    coinId: option?.coin?.id,
  })?.split(decimal);

  return (
    <Option selected={selected} onClick={() => handleSelect(option)}>
      <Row>
        <Image
          width={50}
          height={50}
          alt={`coin-icon-${option?.coin?.name}`}
          format="square"
          src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/coins/rounded/${option?.coin?.image}`}
          secondarySkeleton
        />
        <Column>
          <CoinSymbol>{option?.coin?.symbol}</CoinSymbol>
          <CoinName>{option?.coin?.name}</CoinName>
        </Column>
      </Row>

      <BalanceContainer>
        <span className="balance">
          {formattedValue?.[0]}
          {formattedValue?.[1] && (
            <div className="decimal">.{formattedValue?.[1]}</div>
          )}
        </span>
        <span className="value">
          {WalletService.maskCurrency({
            value: option?.balance * option?.coin?.price,
            coinId: CoinID.USD,
          })}
        </span>

        {isInvestmentWallet && (
          <ToolTip titleComponent={t('profit_investment')}>
            <span className="value">
              {WalletService.maskCurrency({
                value: option?.profit as number,
                coinId: option?.coin?.id,
              })}
            </span>
          </ToolTip>
        )}
      </BalanceContainer>
    </Option>
  );
}
