import { useState } from 'react';
import { AutocompleteMenuSelectorProps } from './props';
import { AnimatePresence, motion } from 'framer-motion';
import { overlayVariant } from './variants';
import { FieldValues } from 'react-hook-form';
import SelectedOption from './SelectedOption';
import OptionsDrawer from './OptionsDrawer';
import styles from './styles.module.scss';

export function AutocompleteMenuSelector<T extends FieldValues, V>({
  options,
  setValue,
  value,
  filterBy,
  getOptionLabel,
  getOptionValue,
  customComponents,
  disabled,
  hasNextPage = false,
  loadMore = () => undefined,
  loading = false,
  loadingMore = false,
  isAsync = false,
  title,
  isContainerColor,
  isSmallerDrawer,
  hideSearch,
  onSearchChange,
}: AutocompleteMenuSelectorProps<T, V>) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <SelectedOption
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        open={open}
        loading={loading}
        options={options}
        setOpen={setOpen}
        isContainerColor={isContainerColor}
        value={value}
        customComponents={customComponents}
        disabled={disabled}
      />

      <AnimatePresence>
        {open && !disabled && (
          <>
            <motion.div
              className={styles.overlay}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={overlayVariant}
            />
            <OptionsDrawer
              filterBy={filterBy}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              options={options}
              setOpen={setOpen}
              setValue={setValue}
              title={title}
              value={value}
              customComponents={customComponents}
              hasNextPage={hasNextPage}
              isAsync={isAsync}
              loadMore={loadMore}
              loading={loading}
              loadingMore={loadingMore}
              isSmallerDrawer={isSmallerDrawer}
              hideSearch={hideSearch}
              onSearchChange={onSearchChange}
            />
          </>
        )}
      </AnimatePresence>
    </>
  );
}
