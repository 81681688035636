import { CookiesKey } from '@/enums/cookiesKey';
import { usePathname } from '@package/hooks';
import { setCookie } from 'cookies-next';
import { useRouter } from 'next/router';

export function useSetLanguage() {
  const { push, query, asPath } = useRouter();
  const pathname: string = usePathname();

  function setLanguage(language: string) {
    setCookie(CookiesKey.Lang, language, {
      sameSite: 'strict',
      maxAge: 31536000,
    });
    setCookie('NEXT_LOCALE', language);
    push({ pathname, query }, asPath, { locale: language });
  }

  return setLanguage;
}
