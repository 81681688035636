import { getComputedColor } from '@package/src/utils/theme';
import Color from 'color';
import styled from 'styled-components';

export const RechartsTooltip = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
  background-color: ${({ theme }) =>
    theme.vars.palette.mode == 'dark'
      ? Color(getComputedColor(theme.vars.palette.background.default))
          .lighten(0.5)
          .toString()
      : Color(getComputedColor(theme.vars.palette.background.default))
          .darken(0.05)
          .toString()};
  font-size: 12px;
  color: ${({ theme }) => theme.vars.palette.text.secondary};
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;

  & p {
    color: ${({ theme }) => theme.vars.palette.text.primary};
  }
`;
