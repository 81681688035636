import styles from '../styles.module.scss';
import { PaginationComponentSkeletonProps } from './props';
import skeleton from './styles.module.scss';

export default function PaginationComponentSkeleton({
  secondaryStyles,
}: PaginationComponentSkeletonProps) {
  return (
    <div className={styles.container}>
      <div className={styles.container__content}>
        {Array.from({ length: 4 }).map((_, index) => (
          <div
            className={
              secondaryStyles ? skeleton['icon--secondary'] : skeleton.icon
            }
            key={`pagination-icon-skeleton-${index}`}
          />
        ))}
      </div>
    </div>
  );
}
