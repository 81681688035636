import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export function useDetectContainerVerticalScroll() {
  const ref: MutableRefObject<any> = useRef<any>(null);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);

  const onScroll = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const maxY: number = 50;

    setHasScrolled(ref.current.scrollTop > maxY);
  }, []);

  function onMount() {
    const element = ref.current;

    if (!element) {
      return;
    }

    element.addEventListener('scroll', onScroll);
    onScroll();

    return () => {
      element.removeEventListener('scroll', onScroll);
    };
  }

  useEffect(onMount, [onScroll]);

  return {
    ref,
    hasScrolled,
  };
}
