import { FieldValues } from 'react-hook-form';
import { ListItemProps } from './props';
import styles from './styles.module.scss';
import { memo } from 'react';

function ListItem<T extends FieldValues, V>({
  getOptionLabel,
  getOptionValue,
  option,
  setValue,
  customComponents,
  loading,
  setOpen,
  value,
}: ListItemProps<T, V>) {
  function handleSelectFilter(value: T) {
    setValue(value);
    setOpen(false);
  }

  if (loading || !option) {
    if (!!customComponents?.customSkeletonOption) {
      return customComponents.customSkeletonOption?.();
    }

    return (
      <div className={`${styles.container} ${styles['container--loading']}`}>
        <div className={styles.skeleton} />
      </div>
    );
  }

  const selected: boolean = getOptionValue(option) === value;

  if (!!customComponents?.customOption) {
    return customComponents?.customOption({
      option,
      selected: getOptionValue(option) === value,
      handleSelect: handleSelectFilter,
    });
  }

  return (
    <div
      className={`${styles.container} ${selected ? styles['container--selected'] : ''}`}
      onClick={() => handleSelectFilter(option)}
    >
      {getOptionLabel(option)}
    </div>
  );
}

export default memo(ListItem) as typeof ListItem;
