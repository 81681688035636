import { PaginatedResponse } from '@package/models';
import { InfiniteData, QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

interface BaseEntity {
  id: number | string;
}

export function useRemoveRecordFromInfiniteQuery<T extends BaseEntity>(
  queryKey: QueryKey,
): (id: number | string) => void {
  const queryClient = useQueryClient();

  const updateQueriesData = useCallback(
    (id: number | string, cachedPages?: InfiniteData<PaginatedResponse<T>>) => {
      if (!cachedPages || id === undefined) {
        return cachedPages;
      }

      const updatedPages: PaginatedResponse<T>[] = cachedPages.pages.map(
        page => {
          const filteredData = page.data.filter(record => record.id !== id);
          return { ...page, data: filteredData };
        },
      );

      return {
        ...cachedPages,
        pages: updatedPages,
      };
    },
    [],
  );

  const removeRecordFromInfiniteQuery = useCallback(
    (id: number | string) => {
      queryClient.setQueriesData<InfiniteData<PaginatedResponse<T>>>(
        {
          queryKey,
        },
        pages => updateQueriesData(id, pages),
      );
    },
    //eslint-disable-next-line
    [queryClient, updateQueriesData],
  );

  return removeRecordFromInfiniteQuery;
}
