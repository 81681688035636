import { useEffect, useRef, useCallback } from 'react';
import { UseRouletteProps } from './props';
import { RouletteType } from '@package/enums';

export function useRoulette({
  prizeIndex,
  spinningTime,
  start,
  callback,
  firstItem = 5,
  type,
}: UseRouletteProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const paddingInline = 8;

  const calculateNewPosition = useCallback(
    (position: number, children: HTMLDivElement[]): number => {
      if (!children.length || !containerRef.current) return 0;

      const childWidth = children[0].clientWidth + paddingInline;
      const containerWidth = containerRef.current.clientWidth;

      return -(position * childWidth) + containerWidth / 2 - childWidth / 2;
    },
    [paddingInline],
  );

  const handleStandByState = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    const children = Array.from(
      containerRef.current.children,
    ) as HTMLDivElement[];
    const newPosition = calculateNewPosition(firstItem, children);

    containerRef.current.style.left = `${newPosition}px`;
    containerRef.current.style.transition =
      type == RouletteType.Infinity ? '0s' : '1s ease-in';
  }, [firstItem, calculateNewPosition, type]);

  const handleStartSpinning = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    const container = containerRef.current;
    const children = Array.from(container.children) as HTMLDivElement[];
    const newPosition = calculateNewPosition(prizeIndex, children);

    container.style.left = `${newPosition}px`;
    container.style.transition = `left ${spinningTime}s ease-out`;

    const onTransitionEnd = () => {
      callback?.();
      container.removeEventListener('transitionend', onTransitionEnd);
    };

    container.addEventListener('transitionend', onTransitionEnd);
  }, [prizeIndex, spinningTime, callback, calculateNewPosition]);

  function handleRouletteState() {
    if (start) {
      handleStartSpinning();
    } else {
      handleStandByState();
    }
  }

  useEffect(handleRouletteState, [
    start,
    handleStartSpinning,
    handleStandByState,
  ]);

  return {
    containerRef,
  };
}
