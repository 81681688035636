import styled from 'styled-components';
import Color from 'color';
import { SkeletonProps } from './props';
import { getComputedColor } from '@package/src/utils/theme';

export default styled.div<SkeletonProps>`
  background-image: linear-gradient(
    -90deg,
    ${({ theme, inverse }) =>
        Color(
          inverse
            ? getComputedColor(theme.vars.palette.background.default)
            : getComputedColor(theme.vars.palette.background.paper),
        )
          .darken(0.3)
          .toString()}
      0%,
    ${({ theme, inverse }) =>
        Color(
          inverse
            ? getComputedColor(theme.vars.palette.background.default)
            : getComputedColor(theme.vars.palette.background.paper),
        )
          .darken(0.1)
          .toString()}
      50%,
    ${({ theme, inverse }) =>
        Color(
          inverse
            ? getComputedColor(theme.vars.palette.background.default)
            : getComputedColor(theme.vars.palette.background.paper),
        )
          .darken(0.3)
          .toString()}
      100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;

  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;
