import styles from './styles.module.scss';
import { BarPasswordStrongProps, PasswordStrengthLevel } from './props';
import useTranslation from 'next-translate/useTranslation';

export function BarPasswordStrong({ forceScore }: BarPasswordStrongProps) {
  const { t } = useTranslation('common');
  function getStregthLevel(): PasswordStrengthLevel {
    switch (Math.max(0, forceScore)) {
      case 0:
      case 1:
        return PasswordStrengthLevel.Danger;
      case 2:
        return PasswordStrengthLevel.Warning;
      case 3:
      case 4:
        return PasswordStrengthLevel.Success;
      default:
        return PasswordStrengthLevel.Danger;
    }
  }
  const stregthLevel: PasswordStrengthLevel = getStregthLevel();
  function getStep(): number {
    switch (stregthLevel) {
      case PasswordStrengthLevel.Danger:
        return 1;
      case PasswordStrengthLevel.Warning:
        return 2;
      case PasswordStrengthLevel.Success:
        return 3;
      default:
        return 0;
    }
  }
  const step: number = getStep();
  return (
    <div className={styles.container}>
      <div className={styles.container__progress}>
        <div
          className={`${styles.container__progress__force} ${step >= 1 ? styles[`container__progress__force--${stregthLevel}`] : ''}`}
        />
        <div
          className={`${styles.container__progress__force} ${step >= 2 ? styles[`container__progress__force--${stregthLevel}`] : ''}`}
        />
        <div
          className={`${styles.container__progress__force} ${step >= 3 ? styles[`container__progress__force--${stregthLevel}`] : ''}`}
        />
      </div>

      <span
        className={`${styles.container__label} ${styles['container__label-force']} ${styles[`container__label-force--${stregthLevel}`]}`}
      >
        {t(`label_${stregthLevel}`)}
      </span>
    </div>
  );
}
