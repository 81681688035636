import useTranslation from 'next-translate/useTranslation';

import styles from './styles.module.scss';
import CodeComponent from './Code';
import { Button, FormGroup, Input } from '@package/components';
import Section from '@/components/core/Section';

export default function WorkWithUsSection() {
  const { t } = useTranslation('common');
  return (
    <Section
      adjustLeft
      title={t('work_with_us')}
      description={t('work_with_us_description')}
    >
      <div className={styles.container__wrapper}>
        <CodeComponent />
        <form className={styles.container__form}>
          <span>
            <FormGroup label={t('form.name')}>
              <Input placeholder={t('form.name')} isContainerColor />
            </FormGroup>
            <FormGroup label={t('form.email')}>
              <Input placeholder={t('form.email')} isContainerColor />
            </FormGroup>
            <FormGroup label={t('form.message')}>
              <textarea
                rows={10}
                className={styles.container__form__input}
                placeholder={t('form.message')}
              />
            </FormGroup>
            <Button>{t('form.submit')}</Button>
          </span>
        </form>
      </div>
    </Section>
  );
}
