import { PaginatedResponse } from '@package/models';
import { Comparator } from '@package/src/interfaces/comparator';
import { InfiniteData, QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export function useAddNewRecordToInfiniteQuery<
  T extends { id: number | string },
>(queryKey: QueryKey): (record: T, comparator?: Comparator<T>) => void {
  const queryClient = useQueryClient();

  const isNewRecordInPage = useCallback((records: T[], record: T): boolean => {
    return !!records.find(round => round.id === record.id);
  }, []);

  const updateQueriesData = useCallback(
    (
      record: T,
      cachedPages: InfiniteData<PaginatedResponse<T>>,
      comparator?: Comparator<T>,
    ) => {
      if (!cachedPages || !record) {
        return cachedPages;
      }

      let firstPage: PaginatedResponse<T> = { ...cachedPages.pages?.[0] };

      if (isNewRecordInPage(firstPage.data, record)) {
        return cachedPages;
      }

      firstPage.data.unshift(record);

      if (comparator) {
        firstPage.data.sort(comparator);
      }

      let updatedPages: PaginatedResponse<T>[] = [...cachedPages.pages];
      updatedPages.splice(0, 1, firstPage);

      return {
        ...cachedPages,
        pages: updatedPages,
      };
    },
    [isNewRecordInPage],
  );

  const addNewRecordToInfiniteQuery = useCallback(
    (record: T, comparator?: Comparator<T>) => {
      queryClient.setQueriesData<InfiniteData<PaginatedResponse<T>>>(
        {
          queryKey,
        },
        pages => updateQueriesData(record, pages, comparator),
      );
    },
    //eslint-disable-next-line
    [queryClient, updateQueriesData],
  );

  return addNewRecordToInfiniteQuery;
}
