import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import Image from 'next/image';
import { languageMapping, LanguageProps } from '@/constants/languageMapping';
import { SelectInput } from '@starsoft/common/components';
import { useSetLanguage } from '#root/hooks/useSetLanguage';

export default function LanguageButton() {
  const { t, lang } = useTranslation('common');
  const setLanguage = useSetLanguage();

  function handleSetLanguage(language: LanguageProps) {
    setLanguage(language?.name);
  }

  return (
    <div className={styles.wrapper}>
      <SelectInput
        options={[...languageMapping.values()]}
        getOptionLabel={item => t(item.name as string)}
        getOptionValue={item => item.name}
        value={lang}
        setValue={handleSetLanguage}
        cardBg
        customComponents={{
          customInput: config => (
            <div
              className={styles.container}
              onClick={config.handleOpen}
              id="input-select-button"
            >
              <Image
                width={20}
                height={20}
                src={`/flags/${config?.option?.name}.svg`}
                alt="flag-image"
                className={styles.container__image}
              />
              <p className={styles.container__label}>
                {t(config.option?.name)}
              </p>
              <i
                className={`fa-solid fa-chevron-down ${styles.container__icon} ${config.open ? styles['container__icon--open'] : ''}`}
              />
            </div>
          ),
          customOption: option => (
            <div
              className={`${styles.option} ${option?.selected ? styles['option--active'] : ''}`}
              onClick={() => option?.handleSelect(option?.option)}
            >
              <Image
                width={20}
                height={20}
                src={`/flags/${option?.option?.name}.svg`}
                alt="flag-image"
                className={styles.container__image}
              />
              <p className={styles.container__label}>
                {t(option.option?.name)}
              </p>
            </div>
          ),
        }}
      />
    </div>
  );
}
