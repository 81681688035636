import {
  InfiniteData,
  QueryClient,
  QueryKey,
  useQueryClient,
} from '@tanstack/react-query';
import { useEffect } from 'react';

export function useInfiniteQueryReset(queryKey: QueryKey) {
  const queryClient: QueryClient = useQueryClient();

  function clearCacheToOnlyFirstPage(oldData?: InfiniteData<unknown>) {
    if (!oldData) {
      return;
    }

    return {
      pages: [oldData.pages?.[0]],
      pageParams: [oldData.pageParams?.[0]],
    };
  }

  function onUnmount() {
    return () => {
      queryClient.setQueriesData<InfiniteData<unknown>>(
        { queryKey },
        clearCacheToOnlyFirstPage,
      );
    };
  }

  //eslint-disable-next-line
  useEffect(onUnmount, []);
}
