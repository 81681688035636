import { CoinID } from '../enums';
import { CoinType, CurrencyOptions } from '../models';

export const coinFormattingMapping: Map<CoinID, CurrencyOptions> = new Map([
  [
    CoinID.BRL,
    {
      symbol: 'R$',
      decimal: ',',
      separator: '.',
      precision: 2,
      isSymbolAfterValue: false,
    },
  ],
  [
    CoinID.USDT,
    {
      symbol: 'USDT',
      decimal: '.',
      separator: ',',
      precision: 2,
      isSymbolAfterValue: true,
    },
  ],
  [
    CoinID.USDC,
    {
      symbol: 'USDC',
      decimal: '.',
      separator: ',',
      precision: 2,
      isSymbolAfterValue: true,
    },
  ],
  [
    CoinID.USD,
    {
      symbol: '$',
      decimal: '.',
      separator: ',',
      precision: 2,
      type: CoinType.Fiat,
      isSymbolAfterValue: false,
    },
  ],
  [
    CoinID.ETH,
    {
      symbol: 'ETH',
      decimal: '.',
      separator: ',',
      precision: 8,
      type: CoinType.Fiat,
      isSymbolAfterValue: true,
    },
  ],
  [
    CoinID.BTC,
    {
      symbol: 'BTC',
      decimal: '.',
      separator: ',',
      precision: 8,
      isSymbolAfterValue: true,
    },
  ],
  [
    CoinID.BNB,
    {
      symbol: 'BNB',
      decimal: '.',
      separator: ',',
      precision: 8,
      isSymbolAfterValue: true,
    },
  ],
  [
    CoinID.SHIB,
    {
      symbol: 'SHIB',
      decimal: '.',
      separator: ',',
      precision: 2,
      isSymbolAfterValue: true,
    },
  ],
  [
    CoinID.DOGE,
    {
      symbol: 'DOGE',
      decimal: '.',
      separator: ',',
      precision: 8,
      isSymbolAfterValue: true,
    },
  ],
  [
    CoinID.XRP,
    {
      symbol: 'XRP',
      decimal: '.',
      separator: ',',
      precision: 8,
      isSymbolAfterValue: true,
    },
  ],
  [
    CoinID.MATIC,
    {
      symbol: 'MATIC',
      decimal: '.',
      separator: ',',
      precision: 8,
      isSymbolAfterValue: true,
    },
  ],
  [
    CoinID.EUR,
    {
      symbol: '€',
      decimal: ',',
      separator: '.',
      precision: 2,
      isSymbolAfterValue: false,
    },
  ],
  [
    CoinID.MXN,
    {
      symbol: 'MXN$',
      decimal: '.',
      separator: ',',
      precision: 2,
      isSymbolAfterValue: false,
    },
  ],
  [
    CoinID.COP,
    {
      symbol: 'COL$',
      decimal: '.',
      separator: ',',
      precision: 2,
      isSymbolAfterValue: false,
    },
  ],
  [
    CoinID.CLP,
    {
      symbol: 'CLP$',
      decimal: '.',
      separator: ',',
      precision: 2,
      isSymbolAfterValue: false,
    },
  ],
  [
    CoinID.ARS,
    {
      symbol: 'ARS$',
      decimal: '.',
      separator: ',',
      precision: 2,
      isSymbolAfterValue: false,
    },
  ],
  [
    CoinID.TRX,
    {
      symbol: 'TRX',
      decimal: '.',
      separator: ',',
      precision: 6,
      isSymbolAfterValue: true,
    },
  ],
  [
    CoinID.SOL,
    {
      symbol: 'SOL',
      decimal: '.',
      separator: ',',
      precision: 8,
      isSymbolAfterValue: true,
    },
  ],
]);
