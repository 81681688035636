import {
  CustomOptionSkeletonWrapper,
  ImageSkeleton,
  LabelSkeleton,
} from './styles';

export default function AutocompleteSelectorOptionWithImageSkeleton() {
  return (
    <CustomOptionSkeletonWrapper>
      <ImageSkeleton />
      <LabelSkeleton />
    </CustomOptionSkeletonWrapper>
  );
}
