import Link from 'next/link';
import NavigationBreadcrumbsSkeleton from './Skeleton';
import { NavigationBreadcrumbsProps } from './props';
import styles from './styles.module.scss';
import { ErrorChip } from '../ErrorChip';

export function NavigationBreadcrumbs({
  currentPageLabel,
  lastPageLabel,
  to,
  isLoading,
  isError,
  refetch,
}: NavigationBreadcrumbsProps) {
  function handleError() {
    refetch?.();
  }

  if (isLoading) {
    return <NavigationBreadcrumbsSkeleton />;
  }

  if (isError) {
    return <ErrorChip action={handleError} />;
  }

  return (
    <div className={styles.container}>
      <Link className={styles.container__link} href={to}>
        {lastPageLabel}
      </Link>

      <i className={`${styles.container__icon} fa-solid fa-chevron-right`} />
      <h1 className={styles.container__text}>{currentPageLabel}</h1>
    </div>
  );
}
