import { ModalProps } from './props';
import { PropsWithChildren } from 'react';
import styles from './styles.module.scss';

export function Modal({
  open,
  children,
  onClose,
}: PropsWithChildren<ModalProps>) {
  return (
    open && (
      <div
        key={`modal-${open}`}
        className={`${styles.overlay} ${open ? styles['overlay--enter'] : 'overlay--exit'}`}
        onClick={onClose}
      >
        {children}
      </div>
    )
  );
}
