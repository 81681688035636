import { useState } from 'react';
import { toPng } from 'html-to-image';
import { UseDowloadComponentProps } from './props';
import { nanoid } from 'nanoid';

export function useDowloadComponent({
  id,
  app,
  onError,
}: UseDowloadComponentProps) {
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  async function convertHtmlElementToPng(
    element: HTMLElement,
  ): Promise<string> {
    return await toPng(element, {
      cacheBust: true,
      includeQueryParams: true,
      skipFonts: true,
    });
  }

  function executeDowload(url: string): void {
    const a = document.createElement('a');
    a.href = url;
    a.download = `${app}-${nanoid()}.png`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  async function handleDowloadImage() {
    try {
      setIsLoadingImage(true);
      const element: Element = document.querySelector(`#${id}`);
      const image: string = await convertHtmlElementToPng(
        element as HTMLElement,
      );
      executeDowload(image);
    } catch (error: unknown) {
      onError();
    } finally {
      setIsLoadingImage(false);
    }
  }

  return {
    isLoadingImage,
    handleDowloadImage,
  };
}
