import { Device } from '@package/enums';

export const imageWidthDeviceMapping: Map<Device, number> = new Map<
  Device,
  number
>([
  [Device.Desktop, 300],
  [Device.Tablet, 150],
  [Device.Mobile, 100],
]);
