import { FieldValues } from 'react-hook-form';
import { PasswordInputProps } from './props';
import styles from './styles.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { Input } from '../Input';
import zxcvbn, { ZXCVBNResult } from 'zxcvbn';
import { ChangeEvent, useState } from 'react';
import { BarPasswordStrong } from './BarPasswordStrong';

export function PasswordInput<T extends FieldValues>({
  name,
  isDirty,
  register,
  password = '',
  isTouched,
}: PasswordInputProps<T>) {
  const { t } = useTranslation('register');
  const { t: tCommon } = useTranslation('common');
  const [passwordStrength, setPasswordStrength] = useState<ZXCVBNResult | null>(
    null,
  );
  const isPasswordEmpty: boolean = password?.length === 0;
  const isValidPasswordLength: boolean =
    password?.length >= 4 && password?.length <= 128;
  const isValidPassword: boolean = isValidPasswordLength && !isPasswordEmpty;

  function handlePasswordChange(e: ChangeEvent<HTMLInputElement>) {
    const newPassword = e.target.value;
    setPasswordStrength(zxcvbn(newPassword));
  }

  function getPasswordError(): string | undefined {
    if (isPasswordEmpty) {
      return tCommon('empty_password');
    }

    if (!isValidPasswordLength) {
      return tCommon('invalid_password_length');
    }
  }

  return (
    <div className={styles.container}>
      <Input
        name={name}
        type="password"
        className={isValidPassword ? styles.container__input : undefined}
        placeholder={t('password_placeholder')}
        register={register}
        registerOptions={{
          required: true,
          onChange: handlePasswordChange,
        }}
        isError={!isValidPassword && isTouched}
        errorMessage={getPasswordError()}
        icon={isValidPassword ? 'fa-solid fa-circle-check' : undefined}
        hasPaddingRight
      />

      {isDirty && !!passwordStrength && password !== '' && (
        <BarPasswordStrong
          forceScore={Number(passwordStrength?.score) as 0 | 1 | 2 | 3 | 4}
        />
      )}
    </div>
  );
}
