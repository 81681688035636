import { Image } from '@package/components';
import { SelectOptionWithImageProps } from './props';
import styles from './styles.module.scss';

export function SelectOptionWithImage<T>({
  option,
  selected,
  handleSelect,
  image,
  description,
  label,
}: SelectOptionWithImageProps<T>) {
  return (
    <div
      className={`${styles.container} ${selected ? styles['container--selected'] : ''}`}
      onClick={() => handleSelect(option)}
    >
      <Image
        width={24}
        height={24}
        src={image}
        alt={`option-icon-${label}`}
        className={styles.container__image}
      />
      <div className={styles.container__label}>
        {label}
        {description ? <p>{description}</p> : <div />}
      </div>
    </div>
  );
}
